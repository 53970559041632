import * as React from 'react';

import { map } from 'lodash';
import { Select, SelectProps } from '@mui/material';
import useFetchReturnResolutions from '../../hooks/useFetchReturnResolutions';

type Props = SelectProps;

const ReturnResolutionSelect: React.FC<Props> = ({ placeholder, ...otherProps }) => {
  const { data: returnResolutions } = useFetchReturnResolutions();

  return (
    <Select {...otherProps} native>
      <option value="">{placeholder || ''}</option>
      {map(returnResolutions, returnResolution => (
        <option key={returnResolution.code} value={returnResolution.code}>
          {returnResolution.label}
        </option>
      ))}
    </Select>
  );
};

export default ReturnResolutionSelect;
