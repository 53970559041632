import * as React from 'react';

import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../../images/logo.svg';

const LogoImage = styled('img')(() => ({
  display: 'block',
  width: '94px',
  height: '59px',
  border: 'none',
}));

const Logo: React.FC = () => {
  return (
    <Box sx={{ margin: '0 auto' }} component={Link} to="/private">
      <LogoImage src={logo} alt="Logo" />
    </Box>
  );
};

export default Logo;
