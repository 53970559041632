import * as React from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { FlagsProvider } from 'react-feature-flags';

import { RetailersDeskApiProvider } from './components/contexts/RetailersDeskApiContext';
import { PopoverProvider } from './components/contexts/PopoverContext';
import { DialogProvider } from './components/contexts/DialogContext';
import { ToastProvider } from './components/contexts/ToastContext';
import { StoreProvider } from './components/contexts/StoreContext';
import { AppInsightsProvider } from './appInsights';
import RootRouter from './components/routers/RootRouter';

import theme from './styles/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15,
      retry: false,
    },
  },
});

const flags = [
  {
    name: 'giftcard',
    isActive: process.env.REACT_APP_FEATURE_GIFTCARD === 'true',
  },
  {
    name: 'returns',
    isActive: process.env.REACT_APP_FEATURE_RETURNS === 'true',
  },
];

const App: React.FC = () => {
  return (
    <FlagsProvider value={flags}>
      <AppInsightsProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <QueryClientProvider client={queryClient}>
            <RetailersDeskApiProvider>
              <DialogProvider>
                <PopoverProvider>
                  <ToastProvider>
                    <StoreProvider>
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <React.Suspense fallback="loading">
                          <RootRouter />
                        </React.Suspense>
                      </ThemeProvider>
                    </StoreProvider>
                  </ToastProvider>
                </PopoverProvider>
              </DialogProvider>
            </RetailersDeskApiProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </AppInsightsProvider>
    </FlagsProvider>
  );
};

export default App;
