import { useQuery } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';

import { FetchStoresData } from '../../types/dataTypes';

const useFetchStores = () => {
  const { retailersDeskApi } = useRetailersDeskApi();

  return useQuery(['stores'], async () => {
    const { data } = await retailersDeskApi.get<FetchStoresData>(`/stores`, {});

    return data;
  });
};

export default useFetchStores;
