import { useLocation } from 'react-router-dom';

type OrderState = { email?: string; phone?: string } | null;

const useOrderStateParams = () => {
  const location = useLocation();
  const state = location.state as OrderState;

  return {
    email: state?.email,
    phone: state?.phone,
  };
};

export default useOrderStateParams;
