import * as React from 'react';

import { isNil } from 'lodash';

import { Toast } from '../../types/dataTypes';

type ContextProps = {
  toast?: Toast;
  createToast: (toast: Toast) => void;
  clearToasts: () => void;
};

type ProviderProps = {
  children: React.ReactNode;
};

const ToastContext = React.createContext<ContextProps>({
  toast: undefined,
  createToast: () => undefined,
  clearToasts: () => undefined,
});

export const ToastProvider: React.FC<ProviderProps> = ({ children }) => {
  const [toast, setToast] = React.useState<Toast | undefined>();

  const contextValue = React.useMemo(() => {
    const createToast = (nextToast: Toast) => {
      setToast(nextToast);
    };

    const clearToasts = () => {
      setToast(undefined);
    };

    return {
      toast,
      createToast,
      clearToasts,
    };
  }, [toast]);

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (isNil(context)) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
