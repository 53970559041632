import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ArrowBackIcon = createSvgIcon(
  <path d="M13.9998 8H8.40976L9.70976 6.71C9.803 6.61676 9.87696 6.50607 9.92742 6.38425C9.97788 6.26243 10.0038 6.13186 10.0038 6C10.0038 5.86814 9.97788 5.73758 9.92742 5.61575C9.87696 5.49393 9.803 5.38324 9.70976 5.29C9.61652 5.19676 9.50583 5.1228 9.38401 5.07234C9.26219 5.02188 9.13162 4.99591 8.99976 4.99591C8.8679 4.99591 8.73733 5.02188 8.61551 5.07234C8.49369 5.1228 8.383 5.19676 8.28976 5.29L5.28976 8.29L5.22976 8.35C5.04351 8.53737 4.93896 8.79082 4.93896 9.055C4.93896 9.31919 5.04351 9.57264 5.22976 9.76L8.22976 12.76C8.41439 12.9484 8.66599 13.0562 8.92976 13.06C9.12838 13.0612 9.32285 13.0031 9.48837 12.8933C9.65389 12.7835 9.78296 12.6269 9.85913 12.4435C9.9353 12.26 9.95511 12.0581 9.91604 11.8633C9.87697 11.6686 9.78079 11.4899 9.63976 11.35L8.27976 10H13.9998C14.7954 10 15.5585 10.3161 16.1211 10.8787C16.6837 11.4413 16.9998 12.2044 16.9998 13C16.9998 13.7957 16.6837 14.5587 16.1211 15.1213C15.5585 15.6839 14.7954 16 13.9998 16H11.9998C11.7345 16 11.4802 16.1054 11.2927 16.2929C11.1051 16.4804 10.9998 16.7348 10.9998 17C10.9998 17.2652 11.1051 17.5196 11.2927 17.7071C11.4802 17.8946 11.7345 18 11.9998 18H13.9998C15.3258 18 16.5976 17.4732 17.5353 16.5355C18.473 15.5979 18.9998 14.3261 18.9998 13C18.9998 11.6739 18.473 10.4022 17.5353 9.46447C16.5976 8.52679 15.3258 8 13.9998 8Z" />,
  'ArrowBack',
);

export default ArrowBackIcon;
