import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const EuroIcon = createSvgIcon(
  <path d="M18.29 16.88C17.16 18.25 15.63 19 14 19C11.61 19 9.55 17.36 8.59 15H14C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13H8.07C8.03 12.67 8 12.34 8 12C8 11.66 8.03 11.33 8.07 11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H8.59C9.56 6.64 11.62 5 14 5C15.63 5 17.16 5.75 18.29 7.12C18.64 7.54 19.27 7.6 19.7 7.25C20.12 6.9 20.18 6.27 19.83 5.84C18.33 4.03 16.21 3 14 3C10.52 3 7.57 5.51 6.47 9H5C4.45 9 4 9.45 4 10C4 10.55 4.45 11 5 11H6.05C6.02 11.33 6 11.66 6 12C6 12.34 6.02 12.67 6.05 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15H6.47C7.57 18.49 10.53 21 14 21C16.21 21 18.33 19.96 19.83 18.16C20.18 17.74 20.12 17.1 19.7 16.75C19.27 16.4 18.64 16.46 18.29 16.88Z" />,
  'Euro',
);

export default EuroIcon;
