import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';

import { CreateReturnParams, CreateReturnData } from '../../types/dataTypes';
import { useStore } from '../contexts/StoreContext';

const useCreateReturn = () => {
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { store } = useStore();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  return useMutation(
    async ({ orderNumber, products, returnItems }: CreateReturnParams) => {
      const { data } = await retailersDeskApi.post<CreateReturnData>(`returns`, {
        orderNumber,
        storeId: store?.id,
        items: map(returnItems, (returnItem, index) => ({
          qtyRequested: returnItem.requested,
          condition: returnItem.condition,
          reason: returnItem.reason,
          resolution: returnItem.resolution,
          orderItemId: get(products, `${index}.itemId`),
        })),
      });

      return data;
    },
    {
      onSuccess: (data, params) => {
        createToast({ type: 'success', text: t('useCreateReturn.success') });
        queryClient.invalidateQueries(['returns']);
        queryClient.invalidateQueries(['orders', params.orderNumber, params.email, params.phone], { exact: true });
      },
      onError: () => {
        createToast({ type: 'error', text: t('useCreateReturn.error') });
      },
    },
  );
};

export default useCreateReturn;
