import * as React from 'react';

import { Box, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HeadingWithIcon from '../shared/HeadingWithIcon';
import LatestOrdersTable from '../shared/order/LatestOrdersTable';
import ArrowRightIcon from '../icons/ArrowRightIcon';

const Orders = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ marginBottom: 6 }}>
        <HeadingWithIcon icon="square">{t('HomePage.texts.orders.title')}</HeadingWithIcon>
      </Box>
      <LatestOrdersTable />
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/private/orders"
        endIcon={<ArrowRightIcon />}
        sx={{ marginTop: 2 }}>
        {t('HomePage.buttons.orders.title')}
      </Button>
    </>
  );
};

const HomePage: React.FC = () => {
  return (
    <Box>
      <Container maxWidth="xl">
        <Orders />
      </Container>
    </Box>
  );
};

export default HomePage;
