import * as React from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { formatCurrency } from '../../../utils/formatUtils';

import { useDialog } from '../../contexts/DialogContext';

import useUpdateGiftCard from '../../hooks/useUpdateGiftCard';

import CloseIcon from '../../icons/CloseIcon';
import InfoIcon from '../../icons/InfoIcon';

const ConfirmUpdateGiftCardDialog: React.FC = () => {
  const { t } = useTranslation();
  const { cardNumber } = useParams();
  const { data: dialogData, closeDialog, isDialogOpen } = useDialog();

  const { mutateAsync: updateGiftCard, isLoading } = useUpdateGiftCard(cardNumber);

  const handleClose = React.useCallback(async () => {
    closeDialog();
  }, [closeDialog]);

  const handleUpdateClick = React.useCallback(async () => {
    try {
      await updateGiftCard({ ...dialogData });
      closeDialog();
    } catch (error) {
      console.error(error);
    }
  }, [closeDialog, updateGiftCard, dialogData]);

  return (
    <Dialog onClose={handleClose} open={isDialogOpen('ConfirmUpdateGiftCardDialog')}>
      <IconButton color="primary" sx={{ position: 'absolute', right: '12px', top: '12px' }} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>{t('ConfirmUpdateGiftCardDialog.title')}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon sx={{ marginRight: '10px' }} />
          <Typography variant="body1">
            {t('ConfirmUpdateGiftCardDialog.body', { balance: formatCurrency(dialogData?.balance) })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" color="secondary" disabled={isLoading} onClick={handleClose}>
          {t('ConfirmUpdateGiftCardDialog.buttons.cancel.title')}
        </Button>
        <Button variant="contained" color="primary" disabled={isLoading} onClick={handleUpdateClick}>
          {t('ConfirmUpdateGiftCardDialog.buttons.update.title')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUpdateGiftCardDialog;
