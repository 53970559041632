import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const InfoIcon = createSvgIcon(
  <path d="M6.4443 20.3147C8.08879 21.4135 10.0222 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51809 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92894 4.92894C3.53041 6.32746 2.578 8.10929 2.19215 10.0491C1.8063 11.9889 2.00433 13.9996 2.76121 15.8268C3.51809 17.6541 4.79981 19.2159 6.4443 20.3147ZM7.55544 5.34825C8.87104 4.4692 10.4178 4 12 4C14.1217 4 16.1566 4.84286 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C20 13.5823 19.5308 15.129 18.6518 16.4446C17.7727 17.7602 16.5233 18.7855 15.0615 19.391C13.5997 19.9965 11.9911 20.155 10.4393 19.8463C8.88743 19.5376 7.46197 18.7757 6.34315 17.6569C5.22433 16.538 4.4624 15.1126 4.15372 13.5607C3.84504 12.0089 4.00347 10.4003 4.60897 8.93854C5.21447 7.47673 6.23985 6.2273 7.55544 5.34825ZM10 17H14.12L14.08 16.53C13.9713 16.5173 13.8641 16.4939 13.76 16.46L13.47 16.36C13.3547 16.3141 13.2591 16.2292 13.2 16.12C13.1283 16.0067 13.0933 15.874 13.1 15.74V10.61L13 10.51L9.84 10.68V11.15L10.27 11.24C10.3918 11.277 10.5092 11.3273 10.62 11.39C10.7261 11.4632 10.8149 11.5588 10.88 11.67C10.9503 11.7876 10.985 11.9231 10.98 12.06V15.85C10.9838 15.9844 10.9564 16.1179 10.9 16.24C10.8311 16.3441 10.7288 16.4217 10.61 16.46C10.5189 16.4916 10.4252 16.515 10.33 16.53H10V17ZM13.0744 8.6346C13.1398 8.49523 13.1757 8.34388 13.18 8.19C13.18 8.03005 13.1451 7.87204 13.0777 7.72696C13.0104 7.58188 12.9122 7.45324 12.79 7.35C12.5509 7.12091 12.2311 6.99518 11.9 7C11.566 6.99575 11.2434 7.12122 11 7.35C10.882 7.44959 10.7869 7.57356 10.7213 7.71338C10.6557 7.8532 10.6211 8.00557 10.62 8.16C10.6181 8.31928 10.6512 8.47703 10.7168 8.62216C10.7825 8.76729 10.8791 8.89626 11 9C11.2475 9.22138 11.5679 9.34377 11.9 9.34377C12.2321 9.34377 12.5525 9.22138 12.8 9C12.9156 8.89835 13.009 8.77396 13.0744 8.6346Z" />,
  'Info',
);

export default InfoIcon;
