import { useQuery } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useStore } from '../contexts/StoreContext';

import { FetchLatestOrdersData } from '../../types/dataTypes';

const useFetchLatestOrders = () => {
  const { retailersDeskApi } = useRetailersDeskApi();
  const { store } = useStore();

  return useQuery(['orders', store?.id, 'latest'], async () => {
    const { data } = await retailersDeskApi.get<FetchLatestOrdersData>(`/orders`, {
      params: {
        locationId: store?.id,
      },
    });

    return data;
  });
};

export default useFetchLatestOrders;
