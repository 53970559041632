import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ChevronUpIcon = createSvgIcon(
  <path d="M17.0021 13.998C17.0029 14.1296 16.9777 14.26 16.9279 14.3819C16.8781 14.5037 16.8048 14.6145 16.7121 14.708C16.6192 14.8017 16.5086 14.8761 16.3867 14.9268C16.2649 14.9776 16.1341 15.0038 16.0021 15.0038C15.8701 15.0038 15.7394 14.9776 15.6176 14.9268C15.4957 14.8761 15.3851 14.8017 15.2921 14.708L12.0021 11.408L8.71214 14.708C8.52383 14.8963 8.26844 15.002 8.00214 15.002C7.87028 15.002 7.73971 14.9761 7.61789 14.9256C7.49607 14.8752 7.38538 14.8012 7.29214 14.708C7.1989 14.6147 7.12494 14.504 7.07448 14.3822C7.02402 14.2604 6.99805 14.1298 6.99805 13.998C6.99805 13.7317 7.10383 13.4763 7.29214 13.288L11.2921 9.28796C11.3851 9.19423 11.4957 9.11983 11.6176 9.06906C11.7394 9.0183 11.8701 8.99216 12.0021 8.99216C12.1342 8.99216 12.2649 9.0183 12.3867 9.06906C12.5086 9.11983 12.6192 9.19423 12.7121 9.28796L16.7121 13.288C16.8048 13.3814 16.8781 13.4922 16.9279 13.614C16.9777 13.7359 17.0029 13.8663 17.0021 13.998Z" />,
  'ChevronUp',
);

export default ChevronUpIcon;
