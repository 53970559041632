import * as React from 'react';

import { Box, ChipProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = { ordered?: number; refunded?: number; returned?: number; shipped?: number } & ChipProps;

const ProductQtyCard: React.FC<Props> = ({ ordered, shipped, returned, refunded }) => {
  const { t } = useTranslation();

  const orderedLabel = t('ProductQtyCard.fields.ordered.label', { ordered });
  const shippedLabel = t('ProductQtyCard.fields.shipped.label', { shipped });
  const returnedLabel = t('ProductQtyCard.fields.returned.label', { returned });
  const refundedLabel = t('ProductQtyCard.fields.refunded.label', { refunded });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body2" sx={{ lineHeight: '16px' }}>
        {orderedLabel}
      </Typography>
      <Typography variant="body2" sx={{ lineHeight: '16px' }}>
        {shippedLabel}
      </Typography>
      <Typography variant="body2" sx={{ lineHeight: '16px' }}>
        {returnedLabel}
      </Typography>
      <Typography variant="body2" sx={{ lineHeight: '16px' }}>
        {refundedLabel}
      </Typography>
    </Box>
  );
};

export default ProductQtyCard;
