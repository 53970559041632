import * as React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { Box, Button, FormControl, FormLabel, InputAdornment, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { getError } from '../../../utils/formikUtils';

import { UpdateGiftCardFormValues } from '../../../types/dataTypes';

import { useDialog } from '../../contexts/DialogContext';

import useFetchGiftCard from '../../hooks/useFetchGiftCard';

import FormErrorText from '../FormErrorText';
import EuroIcon from '../../icons/EuroIcon';
import ConfirmUpdateGiftCardDialog from './ConfirmUpdateGiftCardDialog';
import GiftCardBalanceChip from './GiftCardBalanceChip';
import GiftCardExpirationChip from './GiftCardExpirationChip';

const UpdateGiftCardForm: React.FC = () => {
  const { t } = useTranslation();
  const { cardNumber } = useParams();

  const { data: giftCard } = useFetchGiftCard(cardNumber);
  const { openDialog } = useDialog();

  const validationSchema = React.useMemo(() => {
    const min = 1;
    const max = giftCard?.Balance || 1;

    return Yup.object().shape({
      balance: Yup.number()
        .min(min, t('Validation.errors.min', { min }))
        .max(max, t('Validation.errors.max', { max }))
        .required(t('Validation.errors.required')),
    });
  }, [t, giftCard]);

  const formik = useFormik<UpdateGiftCardFormValues>({
    initialValues: {
      balance: 0,
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        openDialog('ConfirmUpdateGiftCardDialog', values);
        helpers.resetForm();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const isBalanceOverZero = giftCard ? giftCard.Balance > 0 : false;
  const isExpired = giftCard ? new Date(giftCard.ValidBefore) < new Date() : false;

  return (
    <>
      <ConfirmUpdateGiftCardDialog />
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, flexWrap: 'wrap' }}>
          <GiftCardBalanceChip
            color={isBalanceOverZero ? 'success' : 'error'}
            sx={{
              height: '45px',
              paddingLeft: 2,
              paddingRight: 2,
            }}
            balance={giftCard?.Balance}
          />
          <GiftCardExpirationChip
            color={isExpired ? 'error' : 'default'}
            sx={{ height: '45px', paddingLeft: 2, paddingRight: 2 }}
            expiration={giftCard?.ValidBefore}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl error={getError(formik, 'balance')} fullWidth>
            <FormLabel required disabled={!isBalanceOverZero || isExpired}>
              {t('UpdateGiftCardForm.fields.balance.label')}
            </FormLabel>
            <OutlinedInput
              id="balance"
              name="balance"
              type="number"
              placeholder={t('UpdateGiftCardForm.fields.balance.placeholder')}
              disabled={formik.isSubmitting || !isBalanceOverZero || isExpired}
              value={formik.values.balance}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              }
            />
            <FormErrorText error={getError(formik, 'balance')} />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={formik.isSubmitting}
            component={Link}
            to="/private/gift-cards">
            {t('UpdateGiftCardForm.buttons.cancel.title')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting || !isBalanceOverZero || isExpired}
            sx={{ marginLeft: 2 }}>
            {t('UpdateGiftCardForm.buttons.update.title')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UpdateGiftCardForm;
