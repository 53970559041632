/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */

import * as React from 'react';

import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Column, flexRender, RowData, SortDirection, Table } from '@tanstack/react-table';

import ReactTablePagination from './ReactTablePagination';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import ReactTableTextFilter from './ReactTableTextFilter';
import ReactTableNumberFilter from './ReactTableNumberFilter';
import ReactTableSqlDateFilter from './ReactTableSqlDateFilter';
import ReactTableOrderStatusFilter from './ReactTableOrderStatusFilter';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterType?: 'orderStatus' | 'number' | 'text' | 'sqlDate';
  }
}

const getSortIcon = (direction: SortDirection | false) => {
  if (direction === 'asc') {
    return <ChevronDownIcon />;
  }
  if (direction === 'desc') {
    return <ChevronUpIcon />;
  }
  return null;
};

const getFilter = (column: Column<any>, table: Table<any>) => {
  const filterType = column.columnDef.meta?.filterType;

  if (filterType === 'sqlDate') {
    return <ReactTableSqlDateFilter column={column} table={table} />;
  }
  if (filterType === 'orderStatus') {
    return <ReactTableOrderStatusFilter column={column} table={table} />;
  }
  if (filterType === 'number') {
    return <ReactTableNumberFilter column={column} table={table} />;
  }

  return <ReactTableTextFilter column={column} table={table} />;
};

type Props = { table: Table<any>; title: string; tableRowClick?: Function };

const ReactTable: React.FC<Props> = ({ table, title, tableRowClick = undefined }) => {
  const { pageSize, pageIndex } = table.getState().pagination;
  const { t } = useTranslation();
  return (
    <>
      <TableContainer>
        <MuiTable aria-label={title} size="small">
          <TableHead>
            {map(table.getHeaderGroups(), headerGroup => (
              <TableRow key={headerGroup.id}>
                {map(headerGroup.headers, header => (
                  <TableCell component="th" key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <Box>
                        <Box
                          sx={{ display: 'flex', lineHeight: '24px', cursor: 'pointer' }}
                          onClick={header.column.getToggleSortingHandler()}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {getSortIcon(header.column.getIsSorted())}
                        </Box>
                        {header.column.getCanFilter() ? getFilter(header.column, table) : null}
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {map(table.getRowModel().rows, row => (
              <TableRow
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                hover
                key={row.id}
                {...(tableRowClick && {
                  onClick: () => {
                    tableRowClick(row);
                  },
                })}>
                {map(row.getVisibleCells(), cell => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        sx={{ display: 'flex', justifyContent: 'center' }}
        rowsPerPageOptions={[10, 20, 30, { label: 'All', value: table.options.data.length }]}
        component="div"
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': t('ReactTable.pager.perPage') },
          native: true,
        }}
        labelRowsPerPage={t('ReactTable.pager.perPage')}
        onPageChange={(_, page) => {
          table.setPageIndex(page);
        }}
        onRowsPerPageChange={e => {
          const size = e.target.value ? Number(e.target.value) : 10;
          table.setPageSize(size);
        }}
        ActionsComponent={ReactTablePagination}
      />
    </>
  );
};

export default ReactTable;
