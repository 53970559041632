import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const SearchIcon = createSvgIcon(
  <path d="M21.71 20.29L17.31 15.9C18.4084 14.5032 19.0038 12.7769 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7769 19.0038 14.5032 18.4084 15.9 17.31L20.29 21.71C20.383 21.8037 20.4936 21.8781 20.6154 21.9289C20.7373 21.9797 20.868 22.0058 21 22.0058C21.132 22.0058 21.2627 21.9797 21.3846 21.9289C21.5064 21.8781 21.617 21.8037 21.71 21.71C21.8037 21.617 21.8781 21.5064 21.9289 21.3846C21.9797 21.2627 22.0058 21.132 22.0058 21C22.0058 20.868 21.9797 20.7373 21.9289 20.6154C21.8781 20.4936 21.8037 20.383 21.71 20.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z" />,
  'Search',
);

export default SearchIcon;
