import * as React from 'react';

import { Column, Table } from '@tanstack/react-table';
import { Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = { column: Column<any, any>; table: Table<any> };

const ReactTableOrderStatusFilter: React.FC<Props> = ({ column }) => {
  const { t } = useTranslation();

  const filterValue = column.getFilterValue() as string;

  return (
    <Select
      id={column.id}
      name={column.id}
      value={filterValue || ''}
      onChange={event => column.setFilterValue(event.target.value)}
      native>
      <option value="">{t('ReactTableOrderStatusFilter.placeholder')}</option>
      <option value="in_shipping_to_pickup_point">{t(`Order.statuses.in_shipping_to_pickup_point.label`)}</option>
      <option value="ready_for_store_pickup">{t(`Order.statuses.ready_for_store_pickup.label`)}</option>
      <option value="picked_up_from_store">{t(`Order.statuses.picked_up_from_store.label`)}</option>
      <option value="complete">{t(`Order.statuses.complete.label`)}</option>
    </Select>
  );
};

export default ReactTableOrderStatusFilter;
