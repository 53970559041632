import { DateTime } from 'luxon';

export const formatSqlDate = (date = '') => {
  return DateTime.fromSQL(date).toFormat('dd.MM.yyyy');
};

export const formatIsoDate = (date = '') => {
  return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
};

export const formatCurrency = (amount = 0, currency = 'EUR') => {
  const formatter = new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};
