/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-curly-brace-presence */

import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Chip, LinearProgress } from '@mui/material';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { formatSqlDate } from '../../../utils/formatUtils';
import { filterSqlDate } from '../../../utils/reactTableUtils';

import { Order } from '../../../types/dataTypes';

import useFetchLatestOrders from '../../hooks/useFetchLatestOrders';

import OrderStatusChip from './OrderStatusChip';
import CircleIcon from '../../icons/CircleIcon';
import ReactTable from '../table/ReactTable';

const inititalData: any[] = [];

const OrdersTable: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: orders, isLoading } = useFetchLatestOrders();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  const columns = React.useMemo<ColumnDef<Order>[]>(() => {
    return [
      {
        id: 'new',
        header: '',
        enableColumnFilter: false,
        cell: () => <CircleIcon color="tertiary" sx={{ fontSize: 8 }} />,
      },
      {
        accessorKey: 'created_at',
        header: t('OrdersTable.columns.createdAt.title'),
        meta: { filterType: 'sqlDate' },
        filterFn: filterSqlDate,
        cell: ({ row }) => <>{formatSqlDate(row.getValue('created_at'))}</>,
      },
      {
        accessorKey: 'orderNumber',
        header: t('OrdersTable.columns.orderNumber.title'),
        meta: { filterType: 'text' },
        cell: ({ row }) => <Chip label={`#${row.getValue('orderNumber') || ''}`} />,
      },
      {
        id: 'customer',
        accessorFn: row => `${row.shipping.firstname || ''} ${row.shipping.lastname || ''}`,
        header: t('OrdersTable.columns.customer.title'),
        meta: { filterType: 'text' },
      },
      {
        id: 'company',
        accessorFn: row => `${row.shipping.company || ''}`,
        header: t('OrdersTable.columns.company.title'),
        meta: { filterType: 'text' },
      },
      {
        accessorKey: 'status',
        header: t('OrdersTable.columns.status.title'),
        meta: { filterType: 'orderStatus' },
        cell: ({ row }) => <OrderStatusChip status={row.getValue('status')} />,
      },
    ];
  }, [t]);

  const table = useReactTable({
    data: orders?.items || inititalData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const tableRowClick = (row: Row<Order>) => {
    const orderNumber = row.getValue('orderNumber');
    navigate(`/private/orders/${orderNumber}`);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return <ReactTable table={table} title={t('OrdersTable.title')} tableRowClick={tableRowClick} />;
};

export default OrdersTable;
