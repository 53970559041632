import * as React from 'react';

import { Chip, ChipProps } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '../../../types/dataTypes';

import UnavailableIcon from '../../icons/UnavailableIcon';
import AvailableIcon from '../../icons/AvailableIcon';
import InTransitIcon from '../../icons/InTransitIcon';
import ClockIcon from '../../icons/ClockIcon';
import DeliveredIcon from '../../icons/DeliveredIcon';

type Props = { status: OrderStatus } & ChipProps;

const OrderStatusChip: React.FC<Props> = ({ status, sx, ...otherProps }) => {
  const { t } = useTranslation();

  let icon;
  let color;

  switch (status) {
    case 'ready_for_store_pickup':
      icon = <ClockIcon />;
      color = 'warning.main';
      break;
    case 'picked_up_from_store':
      icon = <AvailableIcon sx={{ fontSize: 28 }} />;
      color = 'success.main';
      break;
    case 'in_shipping_to_pickup_point':
      icon = <InTransitIcon />;
      color = yellow[800];
      break;
    case 'complete':
      icon = <DeliveredIcon />;
      color = 'success.dark';
      break;
    default:
      icon = <UnavailableIcon />;
      color = 'error.main';
  }

  return (
    <Chip
      {...otherProps}
      icon={icon}
      sx={{ ...sx, '& .MuiChip-icon': { color } }}
      label={t(`Order.statuses.${status}.label`)}
    />
  );
};

export default OrderStatusChip;
