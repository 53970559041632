import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const GiftIcon = createSvgIcon(
  <path d="M19 7.67001H16.92C17.72 7.11001 18.37 6.44001 18.59 5.64001C18.77 5.00001 18.76 4.01001 17.71 2.96001C16.66 1.92001 15.67 1.91001 15.03 2.09001C13.67 2.47001 12.67 4.07001 12 5.59001C11.33 4.07001 10.33 2.47001 8.97 2.09001C8.33 1.91001 7.34 1.92001 6.29 2.97001C5.24 4.02001 5.23 5.01001 5.41 5.65001C5.63 6.45001 6.28 7.12001 7.08 7.68001H5C2.79 7.67001 1 9.47001 1 11.67V18.67C1 20.88 2.79 22.67 5 22.67H19C21.21 22.67 23 20.88 23 18.67V11.67C23 9.46001 21.21 7.67001 19 7.67001ZM15.56 4.01001C15.56 4.01001 15.62 4.00001 15.65 4.00001C15.83 4.00001 16.04 4.13001 16.29 4.38001C16.59 4.68001 16.71 4.92001 16.66 5.11001C16.47 5.79001 15.03 6.57001 13.54 7.14001C14.11 5.64001 14.89 4.20001 15.57 4.02001L15.56 4.01001ZM7.71 4.38001C7.96 4.13001 8.18 4.00001 8.35 4.00001C8.38 4.00001 8.41 4.00001 8.44 4.01001C9.12 4.20001 9.9 5.64001 10.47 7.13001C8.97 6.56001 7.53 5.78001 7.35 5.10001C7.3 4.91001 7.42 4.66001 7.72 4.37001L7.71 4.38001ZM3 18.67V11.67C3 10.57 3.9 9.67001 5 9.67001H10.23C9.59 10.41 8.5 11.26 6.75 11.7C6.21 11.83 5.89 12.38 6.02 12.91C6.13 13.37 6.54 13.67 6.99 13.67C7.07 13.67 7.15 13.67 7.23 13.64C8.9 13.22 10.12 12.49 10.99 11.72V20.67H5C3.9 20.67 3 19.77 3 18.67ZM21 18.67C21 19.77 20.1 20.67 19 20.67H13V11.71C13.87 12.48 15.09 13.22 16.78 13.64C16.86 13.66 16.94 13.67 17.02 13.67C17.47 13.67 17.88 13.37 17.99 12.91C18.12 12.37 17.8 11.83 17.26 11.7C15.51 11.26 14.43 10.41 13.79 9.67001H19C20.1 9.67001 21 10.57 21 11.67V18.67Z" />,
  'Gift',
);

export default GiftIcon;
