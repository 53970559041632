import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useStore } from '../contexts/StoreContext';
import { useToast } from '../contexts/ToastContext';
import { formatIsoDate, formatCurrency } from '../../utils/formatUtils';

import { CreateDigitalGiftCardParams, CreateDigitalGiftCardData } from '../../types/dataTypes';

const useCreateDigitalGiftCard = () => {
  const { t } = useTranslation();
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { store } = useStore();

  return useMutation(
    async (params: CreateDigitalGiftCardParams) => {
      const { data } = await retailersDeskApi.post<CreateDigitalGiftCardData>('/gift-cards', {
        SalesOrderPerson: {
          firstName: params.customerFirstName,
          lastName: params.customerLastName,
          email: params.customerEmail,
        },
        SalesOrderRecipient: {
          firstName: params.recipientFirstName,
          lastName: params.recipientLastName,
          phone: params.recipientPhone,
          email: params.recipientEmail,
        },
        Message: {
          title: t('CreateDigitalGiftCardForm.fields.messageTitle.label'),
          body: params.messageBody,
        },
        Balance: params.balance,
        Shop: store?.id,
      });

      return data;
    },
    {
      onSuccess: response => {
        createToast({
          type: 'success',
          text: t('useCreateDigitalGiftCard.success'),
          giftCardDetails: {
            cardNumber: response.CardNumber || '',
            amount: response.Balance ? formatCurrency(response.Balance) : '',
            validBefore: response.ValidBefore ? formatIsoDate(response.ValidBefore) : '',
            authCode: response.AuthorizationCode || '',
          },
        });
      },
      onError: () => {
        createToast({ type: 'error', text: t('useCreateDigitalGiftCard.error') });
      },
    },
  );
};

export default useCreateDigitalGiftCard;
