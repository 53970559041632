import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useStore } from '../contexts/StoreContext';
import { useToast } from '../contexts/ToastContext';

import { CreatePhysicalGiftCardParams, CreatePhysicalGiftCardData } from '../../types/dataTypes';
import { formatCurrency, formatIsoDate } from '../../utils/formatUtils';

const useCreatePhysicalGiftCard = () => {
  const { t } = useTranslation();
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { store } = useStore();

  return useMutation(
    async (params: CreatePhysicalGiftCardParams) => {
      const { data } = await retailersDeskApi.put<CreatePhysicalGiftCardData>(
        `/gift-cards/${params.cardNumber}/add-balance`,
        {
          CardNumber: params.cardNumber,
          BalanceUpdate: params.balance,
          Shop: store?.id,
        },
      );

      return data;
    },
    {
      onSuccess: response => {
        createToast({
          type: 'success',
          text: t('useCreateDigitalGiftCard.success'),
          giftCardDetails: {
            cardNumber: response.CardNumber ? response.CardNumber : '',
            amount: response.Balance ? formatCurrency(response.Balance) : '',
            validBefore: response.ValidBefore ? formatIsoDate(response.ValidBefore) : '',
            authCode: response.AuthorizationCode ? response.AuthorizationCode : '',
          },
        });
      },
      onError: () => {
        createToast({ type: 'error', text: t('useCreatePhysicalGiftCard.error') });
      },
    },
  );
};

export default useCreatePhysicalGiftCard;
