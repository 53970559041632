import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { useDialog } from '../../contexts/DialogContext';

import CloseIcon from '../../icons/CloseIcon';
import useReceiveOrder from '../../hooks/useReceiveOrder';

const ConfirmReceiveOrderDialog: React.FC = () => {
  const { t } = useTranslation();
  const { data: dialogData, closeDialog, isDialogOpen } = useDialog();

  const { mutateAsync: receiveOrder } = useReceiveOrder();

  const handleClose = React.useCallback(async () => {
    try {
      closeDialog();
    } catch (error) {
      console.error(error);
    }
  }, [closeDialog]);

  const handleConfirm = React.useCallback(async () => {
    try {
      closeDialog();
      await receiveOrder({ order: dialogData });
    } catch (error) {
      console.error(error);
    }
  }, [closeDialog, receiveOrder, dialogData]);

  return (
    <Dialog
      onClose={handleClose}
      open={isDialogOpen('ConfirmReceiveOrderDialog')}
      sx={{ '& .MuiPaper-root': { minWidth: '400px', position: 'relative' } }}>
      <IconButton color="primary" sx={{ position: 'absolute', right: '12px', top: '12px' }} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>{t('ConfirmReceiveOrderDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t('ConfirmReceiveOrderDialog.body')}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {t('ConfirmReceiveOrderDialog.buttons.cancel.title')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {t('ConfirmReceiveOrderDialog.buttons.confirm.title')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmReceiveOrderDialog;
