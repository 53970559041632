import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const CloseIcon = createSvgIcon(
  <path d="M13.4099 11.9999L16.7099 8.70994C16.8032 8.6167 16.8771 8.50601 16.9276 8.38419C16.9781 8.26237 17.004 8.1318 17.004 7.99994C17.004 7.86808 16.9781 7.73751 16.9276 7.61569C16.8771 7.49387 16.8032 7.38318 16.7099 7.28994C16.6167 7.1967 16.506 7.12274 16.3842 7.07228C16.2624 7.02182 16.1318 6.99585 15.9999 6.99585C15.8681 6.99585 15.7375 7.02182 15.6157 7.07228C15.4939 7.12274 15.3832 7.1967 15.2899 7.28994L11.9999 10.5899L8.70994 7.28994C8.6167 7.1967 8.50601 7.12274 8.38419 7.07228C8.26237 7.02182 8.1318 6.99585 7.99994 6.99585C7.86808 6.99585 7.73751 7.02182 7.61569 7.07228C7.49387 7.12274 7.38318 7.1967 7.28994 7.28994C7.1967 7.38318 7.12274 7.49387 7.07228 7.61569C7.02182 7.73751 6.99585 7.86808 6.99585 7.99994C6.99585 8.1318 7.02182 8.26237 7.07228 8.38419C7.12274 8.50601 7.1967 8.6167 7.28994 8.70994L10.5899 11.9999L7.28994 15.2899C7.19621 15.3829 7.12182 15.4935 7.07105 15.6154C7.02028 15.7372 6.99414 15.8679 6.99414 15.9999C6.99414 16.132 7.02028 16.2627 7.07105 16.3845C7.12182 16.5064 7.19621 16.617 7.28994 16.7099C7.3829 16.8037 7.4935 16.8781 7.61536 16.9288C7.73722 16.9796 7.86793 17.0057 7.99994 17.0057C8.13195 17.0057 8.26266 16.9796 8.38452 16.9288C8.50638 16.8781 8.61698 16.8037 8.70994 16.7099L11.9999 13.4099L15.2899 16.7099C15.3829 16.8037 15.4935 16.8781 15.6154 16.9288C15.7372 16.9796 15.8679 17.0057 15.9999 17.0057C16.132 17.0057 16.2627 16.9796 16.3845 16.9288C16.5064 16.8781 16.617 16.8037 16.7099 16.7099C16.8037 16.617 16.8781 16.5064 16.9288 16.3845C16.9796 16.2627 17.0057 16.132 17.0057 15.9999C17.0057 15.8679 16.9796 15.7372 16.9288 15.6154C16.8781 15.4935 16.8037 15.3829 16.7099 15.2899L13.4099 11.9999Z" />,
  'Close',
);

export default CloseIcon;
