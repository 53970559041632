import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const DeliveredIcon = createSvgIcon(
  <path d="m21.83,11.45l-2-3c-.19-.28-.5-.45-.83-.45h-3v-1c0-1.65-1.35-3-3-3h-1c-.08,0-.15.03-.22.05-1.01-1.25-2.55-2.05-4.28-2.05-3.04,0-5.5,2.46-5.5,5.5,0,.14.01.27.02.4,0,.03-.02.06-.02.1v8c0,1.65,1.35,3,3,3h.18c.41,1.16,1.51,2,2.82,2s2.4-.84,2.82-2h4.37c.41,1.16,1.51,2,2.82,2,1.57,0,2.86-1.22,2.98-2.77.64-.56,1.02-1.36,1.02-2.23v-4c0-.2-.06-.39-.17-.55Zm-3.37-1.45l1.54,2.3v3.48c-.53-.48-1.23-.78-2-.78s-1.47.3-2,.78v-5.78h2.46Zm-13.73-3.21c.39-.39,1.02-.39,1.41,0l.67.67,2.04-2.04c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-2.75,2.75c-.2.2-.45.29-.71.29s-.51-.1-.71-.29l-1.38-1.38c-.39-.39-.39-1.02,0-1.41Zm-.73,9.21v-4.26c.95.79,2.17,1.26,3.5,1.26,3.04,0,5.5-2.46,5.5-5.5,0-.52-.08-1.02-.21-1.5h.21c.55,0,1,.45,1,1v10h-3.18c-.41-1.16-1.51-2-2.82-2s-2.4.84-2.82,2h-.18c-.55,0-1-.45-1-1Zm4,3c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Zm10,0c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Z" />,
  'DeliveredIcon',
);

export default DeliveredIcon;
