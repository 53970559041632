import * as React from 'react';

const DeveloperPage: React.FC = () => {
  const orderGiftCard = async () => {
    const body = {
      SalesOrderPerson: {
        firstName: 'Ossi',
        lastName: 'Ostaja',
        // always use existing email (otherwise gift card aws account email reputation will get worse)
        email: 'tommi.lehtonen@solteq.com',
      },
      SalesOrderRecipient: {
        firstName: 'Vallu',
        lastName: 'Vastaanottaja',
        phone: '+35840000000',
        // always use existing email (otherwise gift card aws account email reputation will get worse)
        email: 'tommi.lehtonen@solteq.com',
      },
      Message: {
        title: 'Viestin otsake',
        body: 'Ostajan viesti vastaanottajalle',
      },
      Balance: 100,
      Shop: '10220',
    };
    const response = await fetch(`/api/gift-cards`, { method: 'POST', body: JSON.stringify(body) });
    console.log(await response.json());
  };

  const getStores = async () => {
    const id = '15762';
    const response = await fetch(`/api/stores/${id}`);
    console.log(await response.json());
  };

  const healthCheck = async () => {
    const response = await fetch(`/api/health-check`);
    console.log(await response.json());
  };

  const userPrincipal = async () => {
    const response = await fetch(`/.auth/me`);
    console.log(await response.json());
  };

  const getOrders = async () => {
    const params = new URLSearchParams();
    params.append('locationId', '19554');
    params.append('status', 'in_shipping_to_pickup_point');
    params.append('status', 'ready_for_store_pickup');
    params.append('status', 'picked_up_from_store');
    params.append('firstname', 'm%');
    params.append('lastname', 'helin');
    params.append('orderNumber', '000000334');
    params.append('updatedAfter', new Date('2021-08-20T03:24:00').toISOString());

    const response = await fetch(`/api/orders/?${params}`);
    console.log(await response.json());
  };

  const getRefundableOrder = async () => {
    const orderNumber = '000000251';
    const email = 'samulli.partanen@solteq.com';
    const telephone = encodeURIComponent('+358401231234');
    const response = await fetch(`/api/orders/${orderNumber}/by/customer?telephone=${telephone}&email=${email}`);
    console.log(await response.json());
  };

  const getReturns = async () => {
    const params = new URLSearchParams();
    params.append('status', 'pending');
    params.append('status', 'authorized');
    const response = await fetch(`/api/returns/?${params}`);
    console.log(await response.json());
  };

  const getReturnByNumber = async () => {
    const returnNumber = '000000013';
    const response = await fetch(`/api/returns/${returnNumber}`);
    console.log(await response.json());
  };

  const getReturnOptions = async () => {
    const params = new URLSearchParams();
    params.append('lang', 'fi'); // fi,se,en
    params.append('attribute', 'resolution'); // resolution, condition, reason
    const response = await fetch(`/api/returns/options/?${params}`);
    console.log(await response.json());
  };

  const createReturn = async () => {
    const body = {
      orderNumber: '000000499',
      storeId: '19554',
      items: [
        {
          orderItemId: 1075,
          qtyRequested: 1,
          reason: 34,
          condition: 22,
          resolution: 13,
        },
      ],
    };
    const response = await fetch(`/api/returns`, { method: 'POST', body: JSON.stringify(body) });
    const result = await response.json();
    console.log(result);
  };

  const getOrder = async () => {
    const orderNumber = '000000251';
    const response = await fetch(`/api/orders/${orderNumber}/by/store`);
    console.log(await response.json());
  };

  const receiveOrder = async () => {
    const orderNumber = '000000251';
    const response = await fetch(`/api/orders/${orderNumber}/receive`, {
      method: 'POST',
    });
    const result = await response.json();
    console.log(result);
  };

  const deliverOrder = async () => {
    const orderNumber = '000000251';
    const response = await fetch(`/api/orders/${orderNumber}/deliver`, {
      method: 'POST',
      body: JSON.stringify({ pickedUpBy: 'somebody', identityVerified: 'ajokortti' }),
    });
    const result = await response.json();
    console.log(result);
  };

  const putOrderComment = async () => {
    const orderNumber = '000000251';
    const response = await fetch(`/api/orders/${orderNumber}/comment`, {
      method: 'PUT',
      body: JSON.stringify({ comment: `<b>comment</b> ${new Date().toDateString()}` }),
    });
    const result = await response.json();
    console.log(result);
  };

  const getGiftCard = async () => {
    const cardNumber = '0398874700000000154';
    const pin = '795313';
    const response = await fetch(`/api/gift-cards/${cardNumber}?PIN=${pin}`);
    console.log(await response.json());
  };

  const putUseBalance = async () => {
    const cardNumber = '0398874700000000154';
    const storeId = '10220';
    const reserveBalance = 0.1;
    const body = {
      BalanceUpdate: reserveBalance,
      Shop: storeId,
      EventText: 'myyjäntunnus123', // TODO userilta tunnus
    };
    const response = await fetch(`/api/gift-cards/${cardNumber}/use-balance`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    const result = await response.json();
    console.log(result);
  };

  const putAddBalance = async () => {
    const cardNumber = '0398874700000000154';
    const storeId = '10220';
    const addBalance = 30.01;
    const body = {
      BalanceUpdate: addBalance,
      Shop: storeId,
      EventText: 'myyjäntunnus123', // TODO userilta tunnus
    };
    const response = await fetch(`/api/gift-cards/${cardNumber}/add-balance`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    const result = await response.json();
    console.log(result);
  };

  return (
    <div>
      <button type="button" onClick={getStores}>
        Get Stores
      </button>
      <button type="button" onClick={orderGiftCard}>
        Order Gift Card
      </button>
      <button type="button" onClick={getGiftCard}>
        Get Giftcard
      </button>
      <br />
      <button type="button" onClick={putUseBalance}>
        Use Gift Card Balance
      </button>
      <button type="button" onClick={putAddBalance}>
        Add Gift Card Balance
      </button>
      <br />
      <button type="button" onClick={healthCheck}>
        Health check
      </button>
      <button type="button" onClick={userPrincipal}>
        /me
      </button>
      <br />
      <button type="button" onClick={getOrders}>
        Get Orders
      </button>
      <button type="button" onClick={getOrder}>
        Get Order #000000251
      </button>
      <button type="button" onClick={receiveOrder}>
        receive #000000251
      </button>
      <button type="button" onClick={deliverOrder}>
        deliver #000000251
      </button>
      <button type="button" onClick={putOrderComment}>
        comment #000000251
      </button>
      <br />
      <button type="button" onClick={getRefundableOrder}>
        Get Refundable Order
      </button>
      <button type="button" onClick={getReturns}>
        Get All Returns
      </button>
      <button type="button" onClick={getReturnByNumber}>
        Get Return by number
      </button>
      <button type="button" onClick={getReturnOptions}>
        Get Return Options
      </button>
      <button type="button" onClick={createReturn}>
        Create return
      </button>
    </div>
  );
};

export default DeveloperPage;
