import { get } from 'lodash';

export const getError = (formik: any, name: string) => {
  const touched = get(formik, `touched.${name}`);
  const error = get(formik, `errors.${name}`);

  if (!touched) {
    return null;
  }

  return error;
};
