import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';
import { useStore } from '../contexts/StoreContext';

import { UpdateGiftCardParams, UpdateGiftCardData } from '../../types/dataTypes';

const useUpdateGiftCard = (cardNumber?: string) => {
  const { t } = useTranslation();
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { store } = useStore();

  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateGiftCardParams) => {
      const { data } = await retailersDeskApi.put<UpdateGiftCardData>(`/gift-cards/${cardNumber}/use-balance`, {
        BalanceUpdate: params.balance,
        EventText: 'N/A',
        Shop: store?.id,
      });

      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['gift-cards', cardNumber]);
        createToast({ type: 'success', text: t('useUpdateGiftCard.success') });
      },
      onError: () => {
        createToast({ type: 'error', text: t('useUpdateGiftCard.error') });
      },
    },
  );
};

export default useUpdateGiftCard;
