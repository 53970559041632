import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const HomeIcon = createSvgIcon(
  <path d="M21.7099 11.29L19.7099 9.29L12.7099 2.29C12.617 2.19627 12.5064 2.12188 12.3845 2.07111C12.2627 2.02034 12.132 1.9942 11.9999 1.9942C11.8679 1.9942 11.7372 2.02034 11.6154 2.07111C11.4935 2.12188 11.3829 2.19627 11.2899 2.29L4.28994 9.29L2.28994 11.29C2.10164 11.4783 1.99585 11.7337 1.99585 12C1.99585 12.2663 2.10164 12.5217 2.28994 12.71C2.47824 12.8983 2.73364 13.0041 2.99994 13.0041C3.26624 13.0041 3.52164 12.8983 3.70994 12.71L3.99994 12.41V20C3.99994 20.2652 4.1053 20.5196 4.29283 20.7071C4.48037 20.8946 4.73472 21 4.99994 21H9.99994C10.2652 21 10.5195 20.8946 10.707 20.7071C10.8946 20.5196 10.9999 20.2652 10.9999 20V13H12.9999V20C12.9999 20.2652 13.1053 20.5196 13.2928 20.7071C13.4804 20.8946 13.7347 21 13.9999 21H18.9999C19.2652 21 19.5195 20.8946 19.707 20.7071C19.8946 20.5196 19.9999 20.2652 19.9999 20V12.41L20.2899 12.71C20.3829 12.8037 20.4935 12.8781 20.6154 12.9289C20.7372 12.9797 20.8679 13.0058 20.9999 13.0058C21.132 13.0058 21.2627 12.9797 21.3845 12.9289C21.5064 12.8781 21.617 12.8037 21.7099 12.71C21.8037 12.617 21.8781 12.5064 21.9288 12.3846C21.9796 12.2627 22.0057 12.132 22.0057 12C22.0057 11.868 21.9796 11.7373 21.9288 11.6154C21.8781 11.4936 21.8037 11.383 21.7099 11.29ZM17.9999 19H14.9999V12C14.9999 11.7348 14.8946 11.4804 14.707 11.2929C14.5195 11.1054 14.2652 11 13.9999 11H9.99994C9.73472 11 9.48037 11.1054 9.29283 11.2929C9.1053 11.4804 8.99994 11.7348 8.99994 12V19H5.99994V10.41L11.9999 4.41L17.9999 10.41V19Z" />,
  'Home',
);

export default HomeIcon;
