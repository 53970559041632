import { useQuery } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';

import { FetchUserProfileData } from '../../types/dataTypes';

const useFetchUserProfile = () => {
  const { retailersDeskApi } = useRetailersDeskApi();

  return useQuery(['users', 'me'], async () => {
    const { data } = await retailersDeskApi.get<FetchUserProfileData>(`/.auth/me`, { baseURL: '/' });

    return data;
  });
};

export default useFetchUserProfile;
