import * as React from 'react';

import { Box, Typography, Button } from '@mui/material';

import SquareIcon from '../icons/SquareIcon';
import GiftIcon from '../icons/GiftIcon';
import SearchIcon from '../icons/SearchIcon';
import ArrowBackIcon from '../icons/ArrowBackIcon';

type Props = {
  icon?: 'square' | 'gift' | 'search' | 'arrowBack';
  children?: React.ReactNode;
};

const Icon: React.FC<Props> = ({ icon }) => {
  if (icon === 'square') {
    return <SquareIcon fontSize="inherit" />;
  }
  if (icon === 'gift') {
    return <GiftIcon fontSize="inherit" />;
  }
  if (icon === 'search') {
    return <SearchIcon fontSize="inherit" />;
  }
  if (icon === 'arrowBack') {
    return <ArrowBackIcon fontSize="inherit" />;
  }

  return null;
};

const HeadingWithIcon: React.FC<Props> = ({ icon, children }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="contained"
        color="secondary"
        sx={{ borderRadius: '50%', minWidth: 0, fontSize: '20px', padding: '8px' }}>
        <Icon icon={icon} />
      </Button>
      <Typography variant="h2" sx={{ marginLeft: 2 }}>
        {children}
      </Typography>
    </Box>
  );
};

export default HeadingWithIcon;
