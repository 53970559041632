/* eslint-disable react/jsx-curly-brace-presence */

import * as React from 'react';

import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Chip, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { formatSqlDate } from '../../../utils/formatUtils';

import useFetchLatestOrders from '../../hooks/useFetchLatestOrders';

import CircleIcon from '../../icons/CircleIcon';
import OrderStatusChip from './OrderStatusChip';

const LatestOrdersTable: React.FC = () => {
  const { t } = useTranslation();

  const { data: orders, isLoading } = useFetchLatestOrders();

  const navigate = useNavigate();

  const tableRowClick = (orderNumber: string) => {
    navigate(`/private/orders/${orderNumber}`);
  };

  let latestOrders;
  if (orders) {
    latestOrders = orders.items.filter(order => {
      return order.status !== 'picked_up_from_store';
    });
  }

  if (isLoading) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table aria-label={t('LatestOrdersTable.title')} size="small">
        <TableBody>
          {map(latestOrders, order => (
            <TableRow
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              hover
              key={order.orderNumber}
              onClick={() => tableRowClick(order.orderNumber)}>
              <TableCell align="center">
                <CircleIcon color="tertiary" sx={{ fontSize: 8 }} />
              </TableCell>
              <TableCell>{formatSqlDate(order.created_at)}</TableCell>
              <TableCell>
                <Chip label={`#${order.orderNumber || ''}`} />
              </TableCell>
              <TableCell>
                {`${order.shipping.firstname || ''} ${order.shipping.lastname || ''}`}
                {!order.shipping.company ? '' : ` (${order.shipping.company})`}
              </TableCell>
              <TableCell align="center">
                <OrderStatusChip status={order.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LatestOrdersTable;
