import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ClockIcon = createSvgIcon(
  <>
    <circle
      cx="12"
      cy="12"
      r="9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="12 7 12 12 17 15"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </>,
  'Clock',
);

export default ClockIcon;
