import * as React from 'react';

import { Column, Table } from '@tanstack/react-table';
import { OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = { column: Column<any, any>; table: Table<any> };

const ReactTableTextFilter: React.FC<Props> = ({ column }) => {
  const { t } = useTranslation();

  const filterValue = column.getFilterValue() as string;

  return (
    <OutlinedInput
      id={column.id}
      name={column.id}
      placeholder={t('ReactTableTextFilter.placeholder')}
      value={filterValue || ''}
      onChange={event => column.setFilterValue(event.target.value)}
    />
  );
};

export default ReactTableTextFilter;
