import * as React from 'react';

import { isNil, size } from 'lodash';
import { Link, Outlet } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { Flags } from 'react-feature-flags';
import { useStore } from '../contexts/StoreContext';
import { useDialog } from '../contexts/DialogContext';

import useFetchLatestOrders from '../hooks/useFetchLatestOrders';

import Logo from './Logo';
import HomeIcon from '../icons/HomeIcon';
import SquareIcon from '../icons/SquareIcon';
import GiftIcon from '../icons/GiftIcon';
import SearchIcon from '../icons/SearchIcon';
import UserAvatar from './UserAvatar';
import Toast from './Toast';
import SelectStoreDialog from './store/SelectStoreDialog';

const drawerWidth = 240;

const RootLayout: React.FC = () => {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { store } = useStore();

  const { data: orders } = useFetchLatestOrders();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleDrawerToggle = React.useCallback(() => {
    setIsDrawerOpen(prevIsDrawerOpen => !prevIsDrawerOpen);
  }, []);

  React.useEffect(() => {
    if (isNil(store)) {
      openDialog('SelectStoreDialog');
    }
  }, [openDialog, store]);

  const drawer = (
    <div>
      <Toolbar sx={{ marginBottom: 3 }}>
        <Logo />
      </Toolbar>
      <List sx={{ marginRight: 3 }}>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/private/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t('RootLayout.buttons.home.title')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <Badge
            badgeContent={size(orders?.items)}
            color="primary"
            sx={{
              flexGrow: 1,
              '& .MuiBadge-badge': {
                top: '24px',
              },
            }}
            showZero>
            <ListItemButton component={Link} to="/private/orders">
              <ListItemIcon>
                <SquareIcon />
              </ListItemIcon>
              <ListItemText primary={t('RootLayout.buttons.orders.title')} />
            </ListItemButton>
          </Badge>
        </ListItem>
        <Flags authorizedFlags={['giftcard']}>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/private/gift-cards">
              <ListItemIcon>
                <GiftIcon />
              </ListItemIcon>
              <ListItemText primary={t('RootLayout.buttons.giftCards.title')} />
            </ListItemButton>
          </ListItem>
        </Flags>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/private/orders/search">
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={t('RootLayout.buttons.search.title')} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <SelectStoreDialog />
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          color="default"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ marginRight: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Box sx={{ marginRight: 5 }}>
                <Badge badgeContent={size(orders?.items)} color="primary" showZero>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/private/orders"
                    sx={{ borderRadius: '50%', minWidth: 0, fontSize: '20px', padding: '8px' }}>
                    <SquareIcon fontSize="inherit" />
                  </Button>
                </Badge>
              </Box>
              <UserAvatar />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}>
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open>
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar />
          <Toast />
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default RootLayout;
