import * as React from 'react';

import { get } from 'lodash';
import { Column, Table } from '@tanstack/react-table';
import { Box, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = { column: Column<any, any>; table: Table<any> };

const ReactTableNumberFilter: React.FC<Props> = ({ column }) => {
  const { t } = useTranslation();

  const filterValue = column.getFilterValue() as [number, number];

  return (
    <Box sx={{ display: 'flex' }}>
      <OutlinedInput
        id={`${column.id}min`}
        name={`${column.id}min`}
        type="number"
        placeholder={t('ReactTableNumberFilter.fields.min.placeholder')}
        value={get(filterValue, '0') || ''}
        onChange={event => {
          column.setFilterValue((prevFilterValue: [number, number]) => [event.target.value, get(prevFilterValue, '1')]);
        }}
        sx={{ width: '80px', marginRight: '2px' }}
      />
      <OutlinedInput
        id={`${column.id}max`}
        name={`${column.id}max`}
        type="number"
        placeholder={t('ReactTableNumberFilter.fields.max.placeholder')}
        value={get(filterValue, '1') || ''}
        onChange={event => {
          column.setFilterValue((prevFilterValue: [number, number]) => [get(prevFilterValue, '0'), event.target.value]);
        }}
        sx={{ width: '80px' }}
      />
    </Box>
  );
};

export default ReactTableNumberFilter;
