import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ArrowRightIcon = createSvgIcon(
  <path d="M17.71 11.2902L12.71 6.29025C12.6168 6.19701 12.5061 6.12305 12.3842 6.07259C12.2624 6.02213 12.1319 5.99615 12 5.99615C11.7337 5.99615 11.4783 6.10194 11.29 6.29025C11.1968 6.38349 11.1228 6.49418 11.0723 6.616C11.0219 6.73782 10.9959 6.86839 10.9959 7.00025C10.9959 7.26655 11.1017 7.52194 11.29 7.71025L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1056 6.29289 11.2931C6.10536 11.4807 6 11.735 6 12.0002C6 12.2655 6.10536 12.5198 6.29289 12.7074C6.48043 12.8949 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3832 11.1219 16.4938 11.0711 16.6157C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1323 11.0203 17.263 11.0711 17.3848C11.1219 17.5067 11.1963 17.6173 11.29 17.7102C11.383 17.804 11.4936 17.8784 11.6154 17.9291C11.7373 17.9799 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9799 12.3846 17.9291C12.5064 17.8784 12.617 17.804 12.71 17.7102L17.71 12.7102C17.8037 12.6173 17.8781 12.5067 17.9289 12.3848C17.9797 12.263 18.0058 12.1323 18.0058 12.0002C18.0058 11.8682 17.9797 11.7375 17.9289 11.6157C17.8781 11.4938 17.8037 11.3832 17.71 11.2902Z" />,
  'ArrowRight',
);

export default ArrowRightIcon;
