import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const AvailableIcon = createSvgIcon(
  <path d="M12.5 4.00012C10.9178 4.00012 9.37103 4.46931 8.05544 5.34837C6.73985 6.22742 5.71447 7.47685 5.10897 8.93865C4.50347 10.4005 4.34504 12.009 4.65372 13.5608C4.9624 15.1127 5.72433 16.5382 6.84315 17.657C7.96197 18.7758 9.38743 19.5377 10.9393 19.8464C12.4911 20.1551 14.0997 19.9967 15.5615 19.3912C17.0233 18.7857 18.2727 17.7603 19.1518 16.4447C20.0308 15.1291 20.5 13.5824 20.5 12.0001C20.5 9.87839 19.6571 7.84356 18.1569 6.34327C16.6566 4.84298 14.6217 4.00012 12.5 4.00012ZM16.21 10.7101L12.21 14.7101C12.117 14.8039 12.0064 14.8782 11.8846 14.929C11.7627 14.9798 11.632 15.0059 11.5 15.0059C11.368 15.0059 11.2373 14.9798 11.1154 14.929C10.9936 14.8782 10.883 14.8039 10.79 14.7101L8.79 12.7101C8.6017 12.5218 8.49591 12.2664 8.49591 12.0001C8.49591 11.7338 8.6017 11.4784 8.79 11.2901C8.97831 11.1018 9.2337 10.996 9.5 10.996C9.7663 10.996 10.0217 11.1018 10.21 11.2901L11.5 12.5901L14.79 9.29012C14.9783 9.10182 15.2337 8.99603 15.5 8.99603C15.7663 8.99603 16.0217 9.10182 16.21 9.29012C16.3983 9.47843 16.5041 9.73382 16.5041 10.0001C16.5041 10.2664 16.3983 10.5218 16.21 10.7101Z" />,
  'Available',
);

export default AvailableIcon;
