import * as React from 'react';

import { isEqual, isNil } from 'lodash';

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  name: string | null;
  anchor: HTMLButtonElement | null;
  openPopover: (name: string, anchor: HTMLButtonElement) => void;
  closePopover: () => void;
  isPopoverOpen: (name: string) => boolean;
};

const PopoverContext = React.createContext<ContextProps>({
  name: null,
  anchor: null,
  openPopover: () => undefined,
  closePopover: () => undefined,
  isPopoverOpen: () => false,
});

export const PopoverProvider: React.FC<ProviderProps> = ({ children }) => {
  const [name, setName] = React.useState<string | null>(null);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);

  const contextValue = React.useMemo(() => {
    const openPopover = (nextName: string, nextAnchor: HTMLButtonElement) => {
      setName(nextName);
      setAnchor(nextAnchor);
    };

    const closePopover = () => {
      setName(null);
      setAnchor(null);
    };

    const isPopoverOpen = (compareName: string) => {
      return isEqual(name, compareName) && !isNil(anchor);
    };

    return {
      name,
      anchor,
      openPopover,
      closePopover,
      isPopoverOpen,
    };
  }, [name, anchor]);

  return <PopoverContext.Provider value={contextValue}>{children}</PopoverContext.Provider>;
};

export const usePopover = () => {
  const context = React.useContext(PopoverContext);
  if (isNil(context)) {
    throw new Error('usePopover must be used within a PopoverProvider');
  }
  return context;
};
