import * as React from 'react';
import { useState } from 'react';

import { isEmpty, lowerCase, map, size } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Typography,
} from '@mui/material';

import { Store } from '../../../types/dataTypes';

import { useDialog } from '../../contexts/DialogContext';
import { useStore } from '../../contexts/StoreContext';

import useFetchStores from '../../hooks/useFetchStores';

import RadioIcon from '../../icons/RadioIcon';

const SelectStoreDialog: React.FC = () => {
  const { t } = useTranslation();
  const { closeDialog, isDialogOpen } = useDialog();
  const { store: selectedStore, updateStore } = useStore();
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  const { data: stores, isLoading } = useFetchStores();

  const handleClose = React.useCallback(async () => {
    try {
      closeDialog();
      // Clear search input when dialog closed
      setSearchText('');
    } catch (error) {
      console.error(error);
    }
  }, [closeDialog]);

  const handleStoreClick = React.useCallback(
    async (store: Store) => {
      try {
        updateStore(store);
        closeDialog();
        // Clear search input when dialog closed
        setSearchText('');
        navigate('/private');
      } catch (error) {
        console.error(error);
      }
    },
    [closeDialog, updateStore, navigate],
  );

  // Filter List by search field
  const filteredStores =
    searchText.length > 0
      ? stores?.filter(store => store.name && lowerCase(store.name).indexOf(lowerCase(searchText)) !== -1)
      : stores;

  return (
    <Dialog
      onClose={handleClose}
      open={isDialogOpen('SelectStoreDialog')}
      sx={{ '& .MuiPaper-root': { minWidth: '400px' } }}>
      <DialogTitle>{t('SelectStoreDialog.title')}</DialogTitle>
      <DialogContent>
        {isLoading ? <LinearProgress /> : null}
        {!isLoading && isEmpty(stores) ? (
          <Typography variant="body1">{t('SelectStoreDialog.stores.empty')}</Typography>
        ) : null}
        {size(stores) > 0 && (
          <OutlinedInput
            fullWidth
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder={t('SelectStoreDialog.stores.search')}
            sx={{ marginBottom: 3 }}
          />
        )}
        <List sx={{ padding: 0 }}>
          {map(filteredStores, store => {
            const selected = store.id === selectedStore?.id;
            return (
              <ListItem key={store.id} selected={selected} disablePadding>
                <ListItemButton onClick={() => handleStoreClick(store)}>
                  {selected ? (
                    <ListItemIcon>
                      <RadioIcon color="secondary" />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText inset={!selected} primary={store.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectStoreDialog;
