import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const InTransitIcon = createSvgIcon(
  <>
    <path d="m21.83,11.45l-2-3c-.19-.28-.5-.45-.83-.45h-3v-1c0-1.65-1.35-3-3-3h-.03c-1.16-1.8-3.17-3-5.47-3C3.92,1,1,3.92,1,7.5c0,1.27.37,2.45,1,3.45v5.05c0,1.65,1.35,3,3,3h.18c.41,1.16,1.51,2,2.82,2s2.4-.84,2.82-2h4.37c.41,1.16,1.51,2,2.82,2,1.57,0,2.86-1.22,2.98-2.77.64-.56,1.02-1.36,1.02-2.23v-4c0-.2-.06-.39-.17-.55Zm-3.37-1.45l1.54,2.3v3.48c-.53-.48-1.23-.78-2-.78s-1.47.3-2,.78v-5.78h2.46ZM7.5,3c2.48,0,4.5,2.02,4.5,4.5s-2.02,4.5-4.5,4.5-4.5-2.02-4.5-4.5S5.02,3,7.5,3Zm-3.5,13v-3.03c1.01.65,2.21,1.03,3.5,1.03,3.58,0,6.5-2.92,6.5-6.5,0-.23-.01-.45-.04-.67.01.06.04.11.04.17v10h-3.18c-.41-1.16-1.51-2-2.82-2s-2.4.84-2.82,2h-.18c-.55,0-1-.45-1-1Zm4,3c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Zm10,0c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Z" />
    <path d="m8.29,9.71c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41l-1.21-1.21v-1.52c0-.55-.45-1-1-1s-1,.45-1,1v1.94c0,.27.11.52.29.71l1.5,1.5Z" />
  </>,
  'InTransitIcon',
);

export default InTransitIcon;
