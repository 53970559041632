import * as React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { Box, Button, Divider, FormControl, FormLabel, OutlinedInput, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { getError } from '../../../utils/formikUtils';

import { SearchGiftCardFormValues } from '../../../types/dataTypes';

import useSearchGiftCard from '../../hooks/useSearchGiftCard';

import SearchIcon from '../../icons/SearchIcon';
import PlusIcon from '../../icons/PlusIcon';
import FormErrorText from '../FormErrorText';
import GiftCardInfo from './GiftCardInfo';

const SearchGiftCardForm: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { mutateAsync: searchGiftCard } = useSearchGiftCard();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        cardNumber: Yup.string().required(t('Validation.errors.required')),
      }),
    [t],
  );

  const formik = useFormik<SearchGiftCardFormValues>({
    initialValues: {
      cardNumber: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await searchGiftCard(values);
        navigate(`/private/gift-cards/${values.cardNumber}`);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              {t('SearchGiftCardForm.titles.search')}
            </Typography>
            <FormControl error={getError(formik, 'cardNumber')} fullWidth>
              <FormLabel required>{t('SearchGiftCardForm.fields.cardNumber.label')}</FormLabel>
              <OutlinedInput
                id="cardNumber"
                name="cardNumber"
                placeholder={t('SearchGiftCardForm.fields.cardNumber.placeholder')}
                disabled={formik.isSubmitting}
                value={formik.values.cardNumber}
                onChange={formik.handleChange}
              />
              <FormErrorText error={getError(formik, 'cardNumber')} />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              startIcon={<SearchIcon />}
              fullWidth>
              {t('SearchGiftCardForm.buttons.search.title')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <GiftCardInfo type="gift-card-search-1" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
            <Divider />
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              {t('SearchGiftCardForm.titles.create')}
            </Typography>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              disabled={formik.isSubmitting}
              startIcon={<PlusIcon />}
              component={Link}
              to="/private/gift-cards/create">
              {t('SearchGiftCardForm.buttons.create.title')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Divider />
          <GiftCardInfo type="gift-card-search-2" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchGiftCardForm;
