import * as React from 'react';

import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';

import useFetchOrder from '../hooks/useFetchOrder';
import useOrderStateParams from '../hooks/useOrderStateParams';

import HeadingWithIcon from '../shared/HeadingWithIcon';
import UpdateOrderStatusButton from '../shared/order/UpdateOrderStatusButton';
import OrderProductsTable from '../shared/product/OrderProductsTable';
import CreateOrderCommentForm from '../shared/order/CreateOrderCommentForm';
import OrderCommentsList from '../shared/order/OrderCommentsList';
import NavigateBackButton from '../shared/NavigateBackButton';

const UpdateOrderPage: React.FC = () => {
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  const { email, phone } = useOrderStateParams();

  const { data: order, isLoading } = useFetchOrder(orderNumber, email, phone);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isNil(order)) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginBottom: 1 }}>
        <NavigateBackButton />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 6 }}>
        <HeadingWithIcon icon="square">{`#${order.orderNumber || ''}`}</HeadingWithIcon>
        <UpdateOrderStatusButton />
      </Box>

      <Box sx={{ marginBottom: 6 }}>
        <Typography variant="h3" sx={{ marginBottom: 4 }}>
          {t('UpdateOrderPage.customer.title')}
        </Typography>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={12} md={5} spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="label">{t('UpdateOrderPage.customer.fields.name.label')}</Typography>
              <Typography variant="body1">
                {`${order.shipping.firstname || ''} ${order.shipping.lastname || ''}`}
              </Typography>
              <Typography variant="body1">{order.shipping.company || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="label">{t('UpdateOrderPage.customer.fields.address.label')}</Typography>
              <Typography variant="body1">{order.shipping.street1 || ''}</Typography>
              <Typography variant="body1">{`${order.shipping.city || ''} ${order.shipping.postcode || ''}`}</Typography>
              <Typography variant="body1">{order.shipping.country || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="label">{t('UpdateOrderPage.customer.fields.email.label')}</Typography>
              <Typography variant="body1">{order.customer.email || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="label">{t('UpdateOrderPage.customer.fields.phone.label')}</Typography>
              <Typography variant="body1">{order.shipping.telephone || ''}</Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={7} spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box>
                <CreateOrderCommentForm />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box>
                <OrderCommentsList />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginBottom: 6 }}>
        <OrderProductsTable />
      </Box>
    </>
  );
};

export default UpdateOrderPage;
