import * as React from 'react';

import { Chip, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../utils/formatUtils';

type Props = { balance?: number } & ChipProps;

const GiftCardBalanceChip: React.FC<Props> = ({ balance, ...otherProps }) => {
  const { t } = useTranslation();

  return <Chip {...otherProps} label={t(`GiftCardBalanceChip.label`, { balance: formatCurrency(balance) })} />;
};

export default GiftCardBalanceChip;
