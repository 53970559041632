import * as React from 'react';
import { Flags } from 'react-feature-flags';

import { isNil } from 'lodash';
import { Box, LinearProgress, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import useFetchGiftCard from '../hooks/useFetchGiftCard';

import UpdateGiftCardForm from '../shared/giftCard/UpdateGiftCardForm';
import HeadingWithIcon from '../shared/HeadingWithIcon';
import GiftCardInfo from '../shared/giftCard/GiftCardInfo';

const UpdateGiftCardPage: React.FC = () => {
  const { cardNumber } = useParams();

  const { data: giftCard, isLoading } = useFetchGiftCard(cardNumber);

  if (isNil(giftCard)) {
    return null;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Flags authorizedFlags={['giftcard']}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 6 }}>
        <HeadingWithIcon icon="gift">{cardNumber}</HeadingWithIcon>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
            <UpdateGiftCardForm />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
            <GiftCardInfo type="gift-card-update" />
          </Box>
        </Grid>
      </Grid>
    </Flags>
  );
};

export default UpdateGiftCardPage;
