import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';

import { FetchGiftCardData } from '../../types/dataTypes';

const useFetchGiftCard = (cardNumber?: string) => {
  const { retailersDeskApi } = useRetailersDeskApi();

  return useQuery(['gift-cards', cardNumber], async () => {
    if (isEmpty(cardNumber)) {
      return null;
    }

    const { data } = await retailersDeskApi.get<FetchGiftCardData>(`/gift-cards/${cardNumber}`);

    return data;
  });
};

export default useFetchGiftCard;
