import { Row } from '@tanstack/react-table';
import { get } from 'lodash';
import { DateTime } from 'luxon';

export const filterSqlDate = (row: Row<any>, columnId: string, filterValue: [string, string]) => {
  const columnValue = row.getValue(columnId) as string;
  const filterValue0 = get(filterValue, '0');
  const filterValue1 = get(filterValue, '1');

  const compareDate = DateTime.fromSQL(columnValue);
  const beforeDate = DateTime.fromSQL(filterValue0);
  const afterDate = DateTime.fromSQL(filterValue1);

  if (compareDate.isValid) {
    if (beforeDate.isValid && compareDate < beforeDate) {
      return false;
    }
    if (afterDate.isValid && compareDate > afterDate) {
      return false;
    }
  }

  return true;
};
