import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const UnavailableIcon = createSvgIcon(
  <path d="M12.5 4C10.9178 4 9.37103 4.46919 8.05544 5.34824C6.73985 6.22729 5.71447 7.47672 5.10897 8.93853C4.50347 10.4003 4.34504 12.0089 4.65372 13.5607C4.9624 15.1126 5.72433 16.538 6.84315 17.6569C7.96197 18.7757 9.38743 19.5376 10.9393 19.8463C12.4911 20.155 14.0997 19.9965 15.5615 19.391C17.0233 18.7855 18.2727 17.7602 19.1518 16.4446C20.0308 15.129 20.5 13.5823 20.5 12C20.5 9.87827 19.6571 7.84344 18.1569 6.34315C16.6566 4.84285 14.6217 4 12.5 4ZM16.21 14.29C16.3037 14.383 16.3781 14.4936 16.4289 14.6154C16.4797 14.7373 16.5058 14.868 16.5058 15C16.5058 15.132 16.4797 15.2627 16.4289 15.3846C16.3781 15.5064 16.3037 15.617 16.21 15.71C16.117 15.8037 16.0064 15.8781 15.8846 15.9289C15.7627 15.9797 15.632 16.0058 15.5 16.0058C15.368 16.0058 15.2373 15.9797 15.1154 15.9289C14.9936 15.8781 14.883 15.8037 14.79 15.71L12.5 13.41L10.21 15.71C10.117 15.8037 10.0064 15.8781 9.88458 15.9289C9.76272 15.9797 9.63202 16.0058 9.5 16.0058C9.36799 16.0058 9.23729 15.9797 9.11543 15.9289C8.99357 15.8781 8.88297 15.8037 8.79 15.71C8.69627 15.617 8.62188 15.5064 8.57111 15.3846C8.52034 15.2627 8.4942 15.132 8.4942 15C8.4942 14.868 8.52034 14.7373 8.57111 14.6154C8.62188 14.4936 8.69627 14.383 8.79 14.29L11.09 12L8.79 9.71C8.6017 9.5217 8.49591 9.2663 8.49591 9C8.49591 8.7337 8.6017 8.4783 8.79 8.29C8.97831 8.1017 9.2337 7.99591 9.5 7.99591C9.7663 7.99591 10.0217 8.1017 10.21 8.29L12.5 10.59L14.79 8.29C14.9783 8.1017 15.2337 7.99591 15.5 7.99591C15.7663 7.99591 16.0217 8.1017 16.21 8.29C16.3983 8.4783 16.5041 8.7337 16.5041 9C16.5041 9.2663 16.3983 9.5217 16.21 9.71L13.91 12L16.21 14.29Z" />,
  'Unavailable',
);

export default UnavailableIcon;
