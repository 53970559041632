import * as React from 'react';

import { Chip, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatIsoDate } from '../../../utils/formatUtils';

type Props = { expiration?: string } & ChipProps;

const GiftCardExpirationChip: React.FC<Props> = ({ expiration, ...otherProps }) => {
  const { t } = useTranslation();

  return <Chip {...otherProps} label={t('GiftCardExpirationChip.label', { expiration: formatIsoDate(expiration) })} />;
};

export default GiftCardExpirationChip;
