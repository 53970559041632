import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';

import { SearchOrderParams, SearchOrderData } from '../../types/dataTypes';

const useSearchOrder = () => {
  const queryClient = useQueryClient();

  const { createToast } = useToast();
  const { retailersDeskApi } = useRetailersDeskApi();
  const { t } = useTranslation();

  return useMutation(
    async (params: SearchOrderParams) => {
      const { data } = await retailersDeskApi.get<SearchOrderData>(`/orders/${params.orderNumber}/by/customer`, {
        params: {
          email: isEmpty(params.email) ? undefined : params.email,
          telephone: isEmpty(params.phone) ? undefined : params.phone,
        },
      });

      return data;
    },
    {
      onError: () => {
        createToast({ type: 'error', text: t('useSearchOrder.error') });
      },
      onSuccess: (data, params) => {
        queryClient.setQueryData(['orders', params.orderNumber, params.email, params.phone], data);
      },
    },
  );
};

export default useSearchOrder;
