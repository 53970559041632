import * as React from 'react';
import { Flags } from 'react-feature-flags';

import { Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import HeadingWithIcon from '../shared/HeadingWithIcon';
import CreateDigitalGiftCardForm from '../shared/giftCard/CreateDigitalGiftCardForm';
import CreatePhysicalGiftCardForm from '../shared/giftCard/CreatePhysicalGiftCardForm';
import GiftCardInfo from '../shared/giftCard/GiftCardInfo';

const CreateGiftCardPage = () => {
  const { t } = useTranslation();

  const [cardType, setCardType] = React.useState<string>('');

  return (
    <Flags authorizedFlags={['giftcard']}>
      <Box>
        <Box sx={{ marginBottom: 6 }}>
          <HeadingWithIcon icon="gift">{t('CreateGiftCardPage.title')}</HeadingWithIcon>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
              <FormControl sx={{ marginBottom: 0 }} fullWidth>
                <FormLabel>{t('CreateGiftCardPage.fields.cardType.label')}</FormLabel>
                <RadioGroup
                  id="cardType"
                  name="cardType"
                  value={cardType}
                  onChange={event => setCardType(event.target.value)}>
                  <FormControlLabel value="digital" control={<Radio />} label={t('CardType.values.digital.label')} />
                  <FormControlLabel value="physical" control={<Radio />} label={t('CardType.values.physical.label')} />
                </RadioGroup>
              </FormControl>
              <Divider />
              {cardType === 'digital' && <CreateDigitalGiftCardForm />}
              {cardType === 'physical' && <CreatePhysicalGiftCardForm />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
              <GiftCardInfo type={cardType} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Flags>
  );
};

export default CreateGiftCardPage;
