import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    label: React.CSSProperties;
    name: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    name?: React.CSSProperties;
  }

  interface ButtonVariants {
    label: React.CSSProperties;
  }

  interface ButtonVariantsOptions {
    label?: React.CSSProperties;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }

  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    name: true;
    label: true;
  }
}

const colors = {
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  border: {
    lightGray: '#DFDFDF',
  },
  brand: {
    blue: '#1A5592',
    turquoise: '#24ADE7',
    red: '#D1403F',
    gray: '#222222',
  },
  text: {
    darkGray: '#222222',
    mediumGray: '#7D7D7D',
    lightGray: '#2C3E47',
  },
  link: {
    darkBlue: '#0F2A46',
    mediumBlue: '#153C64',
    lightBlue: '#1A5592',
  },
  icon: {
    darkGray: '#222222',
    white: '#FFFFFF',
  },
  background: {
    darkGray: '#222222',
    mediumGray: '#1B283C',
    lightGray: '#F0F0F0',
    darkBlue: '#194573',
    white: '#FFFFFF',
  },
  input: {
    darkBlue: '#1A5592',
    mediumGray: '#B4B4B4',
    lightGray: '#ECF2F6',
    white: '#FFFFFF',
  },
  button: {
    primary: {
      darkGray: '#222222',
      mediumGray: '#4E4E4E',
      lightGray: '#4A4A4A',
      black: '#000000',
      white: '#FFFFFF',
    },
    secondary: {
      darkBlue: '#0F2A46',
      mediumBlue: '#153C64',
      lightBlue: '#1A5592',
      lightGray: '#2C3E47',
      white: '#FFFFFF',
    },
  },
  status: {
    info: {
      darkBlue: '#1457BB',
      lightBlue: '#EBF9FF',
    },
    success: {
      darkGreen: '#43A515',
      lightGreen: '#EBFAF1',
    },
    warning: {
      darkOrange: '#F17400',
      lightOrange: '#FFF1CE',
    },
    error: {
      darkRed: '#DD1237',
      lightRed: '#FFE3E8',
    },
  },
};

const fonts = {
  archivo: "'Archivo', sans-serif",
  fira: "'Fira Sans Extra Condensed', sans-serif",
  sen: "'Sen', sans-serif",
};

const defaultTheme = createTheme({});

const theme = createTheme({
  palette: {
    text: {
      primary: colors.text.darkGray,
    },
    primary: {
      main: colors.brand.gray,
      contrastText: colors.common.white,
    },
    secondary: {
      main: colors.brand.blue,
      contrastText: colors.common.white,
    },
    tertiary: {
      main: colors.brand.red,
      contrastText: colors.common.white,
    },
    info: {
      main: colors.status.info.darkBlue,
      contrastText: colors.status.info.lightBlue,
    },
    success: {
      main: colors.status.success.darkGreen,
      contrastText: colors.status.success.lightGreen,
    },
    warning: {
      main: colors.status.warning.darkOrange,
      contrastText: colors.status.warning.lightOrange,
    },
    error: {
      main: colors.status.error.darkRed,
      contrastText: colors.status.error.lightRed,
    },
    background: {
      default: colors.background.white,
      paper: colors.background.lightGray,
    },
    divider: colors.border.lightGray,
  },
  typography: {
    fontFamily: fonts.sen,
    h1: {
      fontFamily: fonts.archivo,
    },
    h2: {
      fontFamily: fonts.archivo,
      fontWeight: 900,
      fontSize: '36px',
      lineHeight: '42px',
    },
    h3: {
      fontFamily: fonts.archivo,
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      fontFamily: fonts.archivo,
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '22px',
    },
    h5: {
      fontFamily: fonts.archivo,
    },
    h6: {
      fontFamily: fonts.archivo,
    },
    body1: {
      fontFamily: fonts.sen,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: fonts.sen,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    subtitle1: {
      fontFamily: fonts.archivo,
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontFamily: fonts.archivo,
      fontWeight: 800,
      fontSize: '14px',
      lineHeight: '18px',
    },
    button: {
      fontFamily: fonts.fira,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'none',
    },
    label: {
      fontFamily: fonts.archivo,
      fontWeight: 800,
      fontSize: '13px',
      lineHeight: '14px',
      textTransform: 'uppercase',
      color: colors.text.darkGray,
    },
    name: {
      fontFamily: fonts.fira,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: colors.text.darkGray,
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('xs')]: {
            minHeight: '80px',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colors.background.lightGray,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: fonts.archivo,
          fontWeight: 800,
          fontSize: '13px',
          lineHeight: '14px',
          textTransform: 'uppercase',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          color: colors.common.white,
          backgroundColor: colors.background.darkGray,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: '48px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.brand.red,
          border: `1px solid ${colors.common.white}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.border.lightGray}`,
        },
        head: {
          fontFamily: fonts.archivo,
          fontWeight: 800,
          fontSize: '13px',
          lineHeight: '14px',
          textTransform: 'uppercase',
          color: colors.text.mediumGray,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: fonts.sen,
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.input.lightGray,
          border: `1px solid ${colors.input.mediumGray}`,
        },
        input: {
          padding: '12px 16px',
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: colors.icon.darkGray,
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: fonts.archivo,
          fontWeight: 800,
          fontSize: '13px',
          lineHeight: '14px',
          textTransform: 'uppercase',
          marginBottom: '8px',
          color: colors.text.darkGray,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
        },
        outlined: {
          backgroundColor: colors.button.secondary.white,
        },
      },
      variants: [
        {
          props: { variant: 'link' },
          style: {
            fontFamily: fonts.sen,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: colors.link.lightBlue,
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '16px 0',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '32px 24px',
          backgroundColor: colors.background.white,
          minWidth: '400px',
          position: 'relative',
        },
        root: {
          '& .MuiDialogTitle-root': {
            fontFamily: fonts.archivo,
            fontWeight: 900,
            fontSize: '24px',
            lineHeight: '28px',
            padding: '0 0 12px  0',
            margin: '0 0 12px  0',
            borderBottom: `1px solid ${colors.background.lightGray}`,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px 0',
          '.MuiDialogTitle-root+&': {
            paddingTop: '24px',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: '48px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '16px',
          lineHeight: '24px',
          flexGrow: 1,
        },
        outlinedInfo: {
          border: '0px solid',
          borderLeft: `4px solid ${colors.status.info.darkBlue}`,
          boxShadow: defaultTheme.shadows['2'],
          '& .MuiAlert-icon': {
            color: colors.status.info.darkBlue,
          },
          '& .MuiAlert-message': {
            color: colors.status.info.darkBlue,
          },
        },
      },
    },
  },
});

export default theme;
