import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';

import { ReceiveOrderParams, ReceiveOrderData } from '../../types/dataTypes';

const useReceiveOrder = () => {
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  return useMutation(
    async ({ order }: ReceiveOrderParams) => {
      const { data } = await retailersDeskApi.post<ReceiveOrderData>(`/orders/${order.orderNumber}/receive`, {});

      return data;
    },
    {
      onSuccess: () => {
        createToast({ type: 'success', text: t('useReceiveOrder.success') });
        queryClient.invalidateQueries(['orders']);
      },
      onError: () => {
        createToast({ type: 'error', text: t('useReceiveOrder.error') });
      },
    },
  );
};

export default useReceiveOrder;
