import * as React from 'react';

import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { useDialog } from '../../contexts/DialogContext';

import useFetchOrder from '../../hooks/useFetchOrder';
import useOrderStateParams from '../../hooks/useOrderStateParams';

import ConfirmReceiveOrderDialog from './ConfirmReceiveOrderDialog';
import ConfirmDeliverOrderDialog from './ConfirmDeliverOrderDialog';
import OrderStatusChip from './OrderStatusChip';

const UpdateOrderStatusButton: React.FC = () => {
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  const { email, phone } = useOrderStateParams();
  const { openDialog } = useDialog();

  const { data: order } = useFetchOrder(orderNumber, email, phone);

  const handleReceiveClick = React.useCallback(() => {
    openDialog('ConfirmReceiveOrderDialog', order);
  }, [openDialog, order]);

  const handleDeliverClick = React.useCallback(() => {
    openDialog('ConfirmDeliverOrderDialog', order);
  }, [openDialog, order]);

  if (isNil(order)) {
    return null;
  }

  if (order.status === 'in_shipping_to_pickup_point') {
    return (
      <>
        <ConfirmReceiveOrderDialog />
        <Button variant="contained" color="primary" onClick={handleReceiveClick}>
          {t('UpdateOrderStatusButton.buttons.receive.title')}
        </Button>
      </>
    );
  }

  if (order.status === 'ready_for_store_pickup') {
    return (
      <>
        <ConfirmDeliverOrderDialog />
        <Button variant="contained" color="primary" onClick={handleDeliverClick}>
          {t('UpdateOrderStatusButton.buttons.deliver.title')}
        </Button>
      </>
    );
  }

  return <OrderStatusChip status={order.status} />;
};

export default UpdateOrderStatusButton;
