import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, PopoverProps } from '@mui/material';

import { usePopover } from '../contexts/PopoverContext';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';

type Props = Omit<PopoverProps, 'open'>;

const UserPopover: React.FC<Props> = ({ ...otherProps }) => {
  const { t } = useTranslation();
  const { anchor, closePopover, isPopoverOpen } = usePopover();

  const name = 'UserPopover';

  return (
    <Popover
      {...otherProps}
      id={isPopoverOpen(name) ? 'UserPopover' : undefined}
      open={isPopoverOpen(name)}
      anchorEl={anchor}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <ListItem>
        <ListItemButton href="/.auth/logout?post_logout_redirect_uri=/public/logout">
          <ListItemIcon>
            <ArrowLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t('UserPopover.buttons.logout.title')} />
        </ListItemButton>
      </ListItem>
    </Popover>
  );
};

export default UserPopover;
