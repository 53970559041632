import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const SquareIcon = createSvgIcon(
  <path d="M22 7.11001C22 7.11001 22 7.05001 22 7.02001C21.98 6.90514 21.9429 6.79391 21.89 6.69001C21.8243 6.57364 21.7359 6.47163 21.63 6.39001H21.56L21.5 6.34001L12.5 1.13001C12.348 1.04224 12.1755 0.996033 12 0.996033C11.8245 0.996033 11.652 1.04224 11.5 1.13001L2.50001 6.32001L2.44001 6.39001H2.36001C2.26842 6.45941 2.19329 6.54819 2.14001 6.65001C2.07268 6.7645 2.02533 6.88962 2.00001 7.02001C2.00001 7.02001 2.00001 7.08001 2.00001 7.11001C2.00001 7.14001 2.00001 7.11001 2.00001 7.19001V17.59C1.99931 17.7662 2.04518 17.9395 2.13299 18.0923C2.22079 18.245 2.3474 18.3719 2.50001 18.46L11.5 23.65C11.5 23.65 11.55 23.65 11.57 23.65H11.66C11.7674 23.6962 11.8831 23.7201 12 23.7201C12.1169 23.7201 12.2326 23.6962 12.34 23.65H12.43C12.43 23.65 12.48 23.65 12.5 23.65L21.5 18.46C21.6526 18.3719 21.7792 18.245 21.867 18.0923C21.9548 17.9395 22.0007 17.7662 22 17.59V7.19001C22 7.19001 22 7.14001 22 7.11001ZM12 3.11001L19 7.11001L12 11.16L5.00001 7.16001L12 3.11001ZM4.00001 8.92001L11 12.97V21.05L4.00001 17.05V8.92001ZM13 21.05V12.97L20 8.92001V17.01L13 21.05Z" />,
  'Square',
);

export default SquareIcon;
