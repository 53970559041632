import * as React from 'react';

import { filter, isEmpty, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { formatSqlDate } from '../../../utils/formatUtils';

import useOrderStateParams from '../../hooks/useOrderStateParams';
import useFetchOrder from '../../hooks/useFetchOrder';

import ChevronVerticalIcon from '../../icons/ChevronVerticalIcon';

type Props = {};

const OrderCommentsList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  const { email, phone } = useOrderStateParams();

  const { data: order } = useFetchOrder(orderNumber, email, phone);

  const comments = React.useMemo(() => {
    return filter(order?.statusChanges, { visible: true });
  }, [order]);

  return (
    <Box sx={{ maxWidth: '500px' }}>
      <Accordion defaultExpanded sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ChevronVerticalIcon />}>
          <Typography variant="label">{t('OrderCommentsList.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {isEmpty(comments) ? <Typography>{t('OrderCommentsList.empty')}</Typography> : null}
            {map(comments, (comment, index) => (
              <ListItem key={index} disablePadding>
                <ListItemText primary={comment.comment} secondary={formatSqlDate(comment.created_at)} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default OrderCommentsList;
