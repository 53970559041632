import { useQuery } from 'react-query';

import { isEmpty } from 'lodash';
import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';

import { FetchOrderData } from '../../types/dataTypes';

const useFetchOrder = (orderNumber?: string, email?: string, phone?: string) => {
  const { retailersDeskApi } = useRetailersDeskApi();

  return useQuery(['orders', orderNumber, email, phone], async () => {
    if (isEmpty(email) && isEmpty(phone)) {
      const { data } = await retailersDeskApi.get<FetchOrderData>(`/orders/${orderNumber}/by/store`);

      return data;
    }

    const { data } = await retailersDeskApi.get<FetchOrderData>(`/orders/${orderNumber}/by/customer`, {
      params: {
        email: isEmpty(email) ? undefined : email,
        telephone: isEmpty(phone) ? undefined : phone,
      },
    });

    return data;
  });
};

export default useFetchOrder;
