import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';

import { CreateOrderCommentParams, CreateOrderCommentData } from '../../types/dataTypes';

const useCreateOrderComment = (orderNumber?: string, email?: string, phone?: string) => {
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  return useMutation(
    async (params: CreateOrderCommentParams) => {
      const { data } = await retailersDeskApi.put<CreateOrderCommentData>(`orders/${orderNumber}/comment`, {
        comment: params.comment,
      });

      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['orders', orderNumber, email, phone], { exact: true });
        createToast({ type: 'success', text: t('useCreateOrderComment.success') });
      },
      onError: () => {
        createToast({ type: 'error', text: t('useCreateOrderComment.error') });
      },
    },
  );
};

export default useCreateOrderComment;
