import * as React from 'react';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  OutlinedInput,
  Radio,
  RadioGroup,
} from '@mui/material';

import { DeliverOrderFormValues } from '../../../types/dataTypes';

import { useDialog } from '../../contexts/DialogContext';

import useDeliverOrder from '../../hooks/useDeliverOrder';

import CloseIcon from '../../icons/CloseIcon';
import FormErrorText from '../FormErrorText';

const ConfirmDeliverOrderDialog: React.FC = () => {
  const { t } = useTranslation();
  const { data: dialogData, closeDialog, isDialogOpen } = useDialog();
  const { mutateAsync: deliverOrder } = useDeliverOrder();

  const formik = useFormik<DeliverOrderFormValues>({
    initialValues: {
      recipient: '',
      recipientName: '',
      verificationMethod: '',
      customVerification: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        await deliverOrder({ ...values, order: dialogData });
        helpers.resetForm();
        closeDialog();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleClose = React.useCallback(async () => {
    try {
      closeDialog();
    } catch (error) {
      console.error(error);
    }
  }, [closeDialog]);

  return (
    <Dialog
      onClose={handleClose}
      open={isDialogOpen('ConfirmDeliverOrderDialog')}
      sx={{ '& .MuiPaper-root': { minWidth: '400px', position: 'relative' } }}>
      <IconButton color="primary" sx={{ position: 'absolute', right: '12px', top: '12px' }} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>{t('ConfirmDeliverOrderDialog.title')}</DialogTitle>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <FormControl error={formik.touched.recipient && !isEmpty(formik.errors.recipient)} fullWidth>
            <FormLabel>{t('ConfirmDeliverOrderDialog.fields.recipient.label')}</FormLabel>
            <RadioGroup id="recipient" name="recipient" value={formik.values.recipient} onChange={formik.handleChange}>
              <FormControlLabel value="customer" control={<Radio />} label={t('Recipient.values.customer.label')} />
              <FormControlLabel
                value="authorizedPerson"
                control={<Radio />}
                label={t('Recipient.values.authorizedPerson.label')}
              />
            </RadioGroup>
            <FormErrorText error={formik.errors.verificationMethod} />
          </FormControl>
          {formik.values.recipient === 'authorizedPerson' ? (
            <FormControl error={formik.touched.recipientName && !isEmpty(formik.errors.recipientName)} fullWidth>
              <FormLabel>{t('ConfirmDeliverOrderDialog.fields.recipientName.label')}</FormLabel>
              <OutlinedInput
                id="recipientName"
                name="recipientName"
                value={formik.values.recipientName}
                onChange={formik.handleChange}
              />
              <FormErrorText error={formik.errors.recipientName} />
            </FormControl>
          ) : null}
          <FormControl
            error={formik.touched.verificationMethod && !isEmpty(formik.errors.verificationMethod)}
            fullWidth>
            <FormLabel>{t('ConfirmDeliverOrderDialog.fields.verificationMethod.label')}</FormLabel>
            <RadioGroup
              id="verificationMethod"
              name="verificationMethod"
              value={formik.values.verificationMethod}
              onChange={formik.handleChange}>
              <FormControlLabel
                value="driversLicence"
                control={<Radio />}
                label={t('VerificationMethod.values.driversLicence.label')}
              />
              <FormControlLabel
                value="identificationCard"
                control={<Radio />}
                label={t('VerificationMethod.values.identificationCard.label')}
              />
              <FormControlLabel
                value="passport"
                control={<Radio />}
                label={t('VerificationMethod.values.passport.label')}
              />
              <FormControlLabel
                value="orderConfirmation"
                control={<Radio />}
                label={t('VerificationMethod.values.orderConfirmation.label')}
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label={t('VerificationMethod.values.custom.label')}
              />
            </RadioGroup>
            <FormErrorText error={formik.errors.verificationMethod} />
          </FormControl>
          {formik.values.verificationMethod === 'custom' ? (
            <FormControl
              error={formik.touched.customVerification && !isEmpty(formik.errors.customVerification)}
              fullWidth>
              <FormLabel>{t('ConfirmDeliverOrderDialog.fields.customVerification.label')}</FormLabel>
              <OutlinedInput
                id="customVerification"
                name="customVerification"
                placeholder={t('ConfirmDeliverOrderDialog.fields.customVerification.placeholder')}
                value={formik.values.customVerification}
                onChange={formik.handleChange}
              />
              <FormErrorText error={formik.errors.customVerification} />
            </FormControl>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('ConfirmDeliverOrderDialog.buttons.cancel.title')}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {t('ConfirmDeliverOrderDialog.buttons.confirm.title')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeliverOrderDialog;
