import * as React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { Box, Button, FormControl, FormLabel, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getError } from '../../../utils/formikUtils';

import { CreateOrderCommentFormValues } from '../../../types/dataTypes';

import useOrderStateParams from '../../hooks/useOrderStateParams';
import useCreateOrderComment from '../../hooks/useCreateOrderComment';

import FormErrorText from '../FormErrorText';

const CreateOrderCommentForm: React.FC = () => {
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  const { email, phone } = useOrderStateParams();

  const { mutateAsync: createOrderComment } = useCreateOrderComment(orderNumber, email, phone);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      comment: Yup.string().required(t('Validation.errors.required')),
    });
  }, [t]);

  const formik = useFormik<CreateOrderCommentFormValues>({
    initialValues: {
      comment: '',
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await createOrderComment({ ...values });
        helpers.resetForm();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ maxWidth: '500px' }}>
      <FormControl error={getError(formik, 'comment')} fullWidth>
        <FormLabel required>{t('CreateOrderCommentForm.fields.comment.label')}</FormLabel>
        <OutlinedInput
          id="comment"
          name="comment"
          placeholder={t('CreateOrderCommentForm.fields.comment.placeholder')}
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          maxRows={10}
          minRows={5}
          multiline
        />
        <FormErrorText error={getError(formik, 'comment')} />
      </FormControl>
      <Button variant="outlined" color="secondary" type="submit" disabled={formik.isSubmitting}>
        {t('CreateOrderCommentForm.buttons.create.title')}
      </Button>
    </Box>
  );
};

export default CreateOrderCommentForm;
