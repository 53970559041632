import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ArrowLeftIcon = createSvgIcon(
  <path d="M6.29586 12.7117L11.2959 17.7117C11.3891 17.8049 11.4998 17.8789 11.6216 17.9294C11.7434 17.9798 11.874 18.0058 12.0059 18.0058C12.2722 18.0058 12.5276 17.9 12.7159 17.7117C12.8091 17.6185 12.8831 17.5078 12.9335 17.386C12.984 17.2641 13.01 17.1336 13.01 17.0017C13.01 16.7354 12.9042 16.48 12.7159 16.2917L9.41586 13.0017L17.0059 13.0017C17.2711 13.0017 17.5254 12.8963 17.713 12.7088C17.9005 12.5213 18.0059 12.2669 18.0059 12.0017C18.0059 11.7365 17.9005 11.4821 17.713 11.2946C17.5254 11.1071 17.2711 11.0017 17.0059 11.0017L9.41586 11.0017L12.7159 7.71171C12.8096 7.61874 12.884 7.50814 12.9348 7.38628C12.9855 7.26442 13.0117 7.13372 13.0117 7.00171C13.0117 6.8697 12.9855 6.73899 12.9348 6.61713C12.884 6.49527 12.8096 6.38467 12.7159 6.29171C12.6229 6.19798 12.5123 6.12358 12.3904 6.07281C12.2686 6.02205 12.1379 5.99591 12.0059 5.99591C11.8738 5.99591 11.7431 6.02205 11.6213 6.07281C11.4994 6.12358 11.3888 6.19798 11.2959 6.29171L6.29586 11.2917C6.20213 11.3847 6.12774 11.4953 6.07697 11.6171C6.0262 11.739 6.00006 11.8697 6.00006 12.0017C6.00006 12.1337 6.0262 12.2644 6.07697 12.3863C6.12774 12.5081 6.20213 12.6187 6.29586 12.7117Z" />,
  'ArrowLeft',
);

export default ArrowLeftIcon;
