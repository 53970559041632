import * as React from 'react';

import { Chip, ChipProps } from '@mui/material';

type Props = { sku?: string } & ChipProps;

const ProductSkuChip: React.FC<Props> = ({ sku, ...otherProps }) => {
  return <Chip {...otherProps} label={sku} />;
};

export default ProductSkuChip;
