import { find, get, includes } from 'lodash';
import { UserProfile } from '../types/dataTypes';

export const getUserProfileName = (userProfile?: UserProfile) => {
  const claim = find(userProfile?.clientPrincipal.claims, { typ: 'name' });
  return get(claim, 'val');
};

export const getUserRole = (userProfile?: UserProfile) => {
  const role = find(userProfile?.clientPrincipal.claims, claim => includes(claim.typ, 'role'));
  return get(role, 'val');
};

export const isUserRoleOTP = (userProfile?: UserProfile) => {
  return getUserRole(userProfile) === 'OTP';
};

export const getGiftCardMinBalance = (userProfile?: UserProfile) => {
  return isUserRoleOTP(userProfile) ? 5 : 20;
};

export const getGiftCardInstructionsFile = (userProfile?: UserProfile) => {
  return isUserRoleOTP(userProfile) ? '/lahjakortti_ohje_otp.pdf' : '/lahjakortti_ohje_jm.pdf';
};
