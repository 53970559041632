/* eslint-disable react/no-unstable-nested-components */

import * as React from 'react';
import * as Yup from 'yup';

import { compact, find, get, isNil, map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, FormControl, FormLabel, OutlinedInput, Typography } from '@mui/material';

import { formatCurrency } from '../../../utils/formatUtils';
import { getError } from '../../../utils/formikUtils';

import { CreateReturnFormValues } from '../../../types/dataTypes';

import useFetchOrder from '../../hooks/useFetchOrder';
import useReturnStateParams from '../../hooks/useReturnStateParams';

import ProductSkuChip from '../product/ProductSkuChip';
import ProductQtyCard from '../product/ProductQtyCard';
import FormErrorText from '../FormErrorText';
import ReturnConditionSelect from './ReturnConditionSelect';
import ReturnReasonSelect from './ReturnReasonSelect';
import ReturnResolutionSelect from './ReturnResolutionSelect';
import useCreateReturn from '../../hooks/useCreateReturn';

const CreateReturnForm: React.FC = () => {
  const { t } = useTranslation();
  const { orderNumber, email, phone, productIds } = useReturnStateParams();

  const navigate = useNavigate();

  const { data: order } = useFetchOrder(orderNumber, email, phone);
  const { mutateAsync: createReturn } = useCreateReturn();

  const products = React.useMemo(() => {
    return compact(map(productIds, productId => find(order?.items, { itemId: productId })));
  }, [order, productIds]);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      returnItems: Yup.array().of(
        Yup.object().shape({
          requested: Yup.number()
            .test('max', '', (value, context) => {
              const index = get(context, 'options.index');
              const max = get(products, `${index}.qtyOrdered`);

              if (!isNil(value) && value > max) {
                return context.createError({ path: context.path, message: t('Validation.errors.max', { max }) });
              }

              return true;
            })
            .min(1, t('Validation.errors.min', { min: 1 }))
            .required(t('Validation.errors.required')),
          condition: Yup.string().required(t('Validation.errors.required')),
          reason: Yup.string().required(t('Validation.errors.required')),
          resolution: Yup.string().required(t('Validation.errors.required')),
        }),
      ),
    });
  }, [t, products]);

  const formik = useFormik<CreateReturnFormValues>({
    initialValues: {
      returnItems: map(products, () => ({
        requested: 1,
        condition: '',
        reason: '',
        resolution: '',
      })),
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await createReturn({ ...values, products, orderNumber, email, phone });
        navigate(-1);
      } catch (error) {
        console.error(error);
      }
    },
  });

  if (isNil(order)) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'background.paper',
          padding: 1.5,
          marginBottom: 3,
        }}>
        <Typography variant="h3" sx={{ lineHeight: '50px' }}>
          {t('CreateReturnForm.title')}
        </Typography>
      </Box>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate>
        {map(products, (product, index) => {
          if (isNil(product)) {
            return null;
          }

          return (
            <Box key={product.itemId}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginRight: 5 }}>
                  <FormControl error={getError(formik, `returnItems.${index}.requested`)} fullWidth>
                    <FormLabel required>{t('CreateReturnForm.fields.requested.label')}</FormLabel>
                    <OutlinedInput
                      id={`returnItems.${index}.requested`}
                      name={`returnItems.${index}.requested`}
                      type="number"
                      placeholder={t('CreateReturnForm.fields.requested.placeholder')}
                      disabled={formik.isSubmitting}
                      value={formik.values.returnItems[index].requested}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorText error={getError(formik, `returnItems.${index}.requested`)} />
                  </FormControl>
                  <FormControl error={getError(formik, `returnItems.${index}.condition`)} fullWidth>
                    <FormLabel required>{t('CreateReturnForm.fields.condition.label')}</FormLabel>
                    <ReturnConditionSelect
                      id={`returnItems.${index}.condition`}
                      name={`returnItems.${index}.condition`}
                      placeholder={t('CreateReturnForm.fields.condition.placeholder')}
                      disabled={formik.isSubmitting}
                      value={formik.values.returnItems[index].condition}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorText error={getError(formik, `returnItems.${index}.condition`)} />
                  </FormControl>
                  <FormControl error={getError(formik, `returnItems.${index}.reason`)} fullWidth>
                    <FormLabel required>{t('CreateReturnForm.fields.reason.label')}</FormLabel>
                    <ReturnReasonSelect
                      id={`returnItems.${index}.reason`}
                      name={`returnItems.${index}.reason`}
                      placeholder={t('CreateReturnForm.fields.reason.placeholder')}
                      disabled={formik.isSubmitting}
                      value={formik.values.returnItems[index].reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorText error={getError(formik, `returnItems.${index}.reason`)} />
                  </FormControl>
                  <FormControl error={getError(formik, `returnItems.${index}.resolution`)} fullWidth>
                    <FormLabel required>{t('CreateReturnForm.fields.resolution.label')}</FormLabel>
                    <ReturnResolutionSelect
                      id={`returnItems.${index}.resolution`}
                      name={`returnItems.${index}.resolution`}
                      placeholder={t('CreateReturnForm.fields.resolution.placeholder')}
                      disabled={formik.isSubmitting}
                      value={formik.values.returnItems[index].resolution}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorText error={getError(formik, `returnItems.${index}.resolution`)} />
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="label" sx={{ minWidth: '100px' }}>
                      {t('CreateReturnForm.fields.name.label')}
                    </Typography>
                    <Typography variant="name">{product.name}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="label" sx={{ minWidth: '100px' }}>
                      {t('CreateReturnForm.fields.sku.label')}
                    </Typography>
                    <ProductSkuChip sku={product.sku} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="label" sx={{ minWidth: '100px' }}>
                      {t('CreateReturnForm.fields.price.label')}
                    </Typography>
                    <Typography variant="body1">{formatCurrency(product.priceInclTax, order.currency)}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="label" sx={{ minWidth: '100px' }}>
                      {t('CreateReturnForm.fields.quantity.label')}
                    </Typography>
                    <ProductQtyCard
                      ordered={product.qtyOrdered}
                      shipped={product.qtyShipped}
                      returned={product.qtyReturned}
                      refunded={product.qtyRefunded}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="label" sx={{ minWidth: '100px' }}>
                      {t('CreateReturnForm.fields.total.label')}
                    </Typography>
                    <Typography variant="body1">
                      {formatCurrency(product.qtyOrdered * product.priceInclTax, order.currency)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>
          );
        })}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={formik.isSubmitting}
            onClick={() => navigate(-1)}
            sx={{ marginRight: 2 }}>
            {t('CreateReturnForm.buttons.cancel.title')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {t('CreateReturnForm.buttons.return.title')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CreateReturnForm;
