import * as React from 'react';

import { isEmpty } from 'lodash';
import { FormHelperText } from '@mui/material';

type Props = {
  error?: string | null;
};

const FormErrorText: React.FC<Props> = ({ error }) => {
  if (isEmpty(error)) {
    return null;
  }

  return <FormHelperText>{error}</FormHelperText>;
};

export default FormErrorText;
