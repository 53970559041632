import { Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '../icons/ArrowBackIcon';

const NavigateBackButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<ArrowBackIcon />}
      onClick={() => {
        navigate(-1);
      }}>
      {t('NavigateBackButton.title')}
    </Button>
  );
};

export default NavigateBackButton;
