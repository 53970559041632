import * as React from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js';

type Props = { children?: React.ReactNode };

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});

export const loadAppInsights = () => {
  if (process.env.NODE_ENV === 'production') {
    appInsights.loadAppInsights();
  }
};

export const AppInsightsProvider: React.FC<Props> = ({ children }) => {
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};
