import * as React from 'react';

import { isEqual, isNil } from 'lodash';

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  name: string | null;
  data?: any | null;
  openDialog: (name: string, data?: any) => void;
  closeDialog: () => void;
  isDialogOpen: (name: string) => boolean;
};

const DialogContext = React.createContext<ContextProps>({
  name: null,
  data: null,
  openDialog: () => undefined,
  closeDialog: () => undefined,
  isDialogOpen: () => false,
});

export const DialogProvider: React.FC<ProviderProps> = ({ children }) => {
  const [name, setName] = React.useState<string | null>(null);
  const [data, setData] = React.useState<any | null | undefined>(null);

  const contextValue = React.useMemo(() => {
    const openDialog = (nextName: string, nextData?: any) => {
      setName(nextName);
      setData(nextData);
    };

    const closeDialog = () => {
      setName(null);
      setData(null);
    };

    const isDialogOpen = (compareName: string) => {
      return isEqual(name, compareName);
    };

    return {
      name,
      data,
      openDialog,
      closeDialog,
      isDialogOpen,
    };
  }, [name, data]);

  return <DialogContext.Provider value={contextValue}>{children}</DialogContext.Provider>;
};

export const useDialog = () => {
  const context = React.useContext(DialogContext);

  if (isNil(context)) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return context;
};
