import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import Logo from '../shared/Logo';
import logo from '../../images/logo.svg';

const LogoImage = styled('img')(() => ({
  display: 'block',
  width: '150px',
  border: 'none',
}));

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <Grid xs={12} container>
      <Grid item sm={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <img
          src="/login.jpg"
          alt="Logo"
          style={{ width: '100%', height: '100vh', objectFit: 'cover', objectPosition: 'right center' }}
        />
      </Grid>

      <Grid item sm={4} xs={12} container alignItems="center">
        <Grid item mx={6}>
          <LogoImage src={logo} alt="Logo" />
          <Box my={2}>
            <Typography variant="subtitle2">{t('LoginPage.subtitle1')}</Typography>
            <Typography variant="subtitle2">{t('LoginPage.subtitle2')}</Typography>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#264E85',
            }}
            href="/.auth/login/aad?post_login_redirect_uri=/private/"
            sx={{ my: 2 }}>
            {t('LoginPage.buttons.login.title')}
          </Button>

          <Box mt={4}>
            <Typography variant="body1">{t('LoginPage.help.body1')}</Typography>
            <Typography variant="body2">{t('LoginPage.help.body2')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
