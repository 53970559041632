import { useLocation } from 'react-router-dom';

type ReturnState = { email?: string; phone?: string; orderNumber?: string; productIds?: string[] } | null;

const useReturnStateParams = () => {
  const location = useLocation();
  const state = location.state as ReturnState;

  return {
    email: state?.email,
    phone: state?.phone,
    orderNumber: state?.orderNumber,
    productIds: state?.productIds,
  };
};

export default useReturnStateParams;
