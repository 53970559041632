import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';

import { SearchGiftCardParams, SearchGiftCardData } from '../../types/dataTypes';

const useSearchGiftCard = () => {
  const queryClient = useQueryClient();

  const { createToast } = useToast();
  const { retailersDeskApi } = useRetailersDeskApi();
  const { t } = useTranslation();

  return useMutation(
    async (params: SearchGiftCardParams) => {
      const { data } = await retailersDeskApi.get<SearchGiftCardData>(`/gift-cards/${params.cardNumber}`);

      return data;
    },
    {
      onError: (error, params) => {
        createToast({ type: 'error', text: t('useSearchGiftCard.error', { cardNumber: params.cardNumber }) });
      },
      onSuccess: (data, params) => {
        queryClient.setQueryData(['gift-cards', params.cardNumber], data);
      },
    },
  );
};

export default useSearchGiftCard;
