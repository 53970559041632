/* eslint-disable react/no-unstable-nested-components */

import * as React from 'react';
import { Flags } from 'react-feature-flags';

import { get, isEmpty, isNil, keys, map } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Chip, Typography } from '@mui/material';
import {
  SortingState,
  ColumnFiltersState,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import { Product } from '../../../types/dataTypes';

import useFetchOrder from '../../hooks/useFetchOrder';
import useOrderStateParams from '../../hooks/useOrderStateParams';

import ReactTableCheckbox from '../table/ReactTableCheckbox';
import ReactTable from '../table/ReactTable';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import ProductQtyCard from './ProductQtyCard';

const inititalData: any[] = [];

const OrderProductsTable: React.FC = () => {
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  const { email, phone } = useOrderStateParams();

  const navigate = useNavigate();

  const { data: order } = useFetchOrder(orderNumber, email, phone);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const handleCreateReturnClick = React.useCallback(() => {
    const productIds = map(keys(rowSelection), productIndex => {
      return get(order?.items, `${productIndex}.itemId`);
    });

    navigate(`/private/returns/create`, {
      state: {
        email,
        phone,
        productIds,
        orderNumber: order?.orderNumber,
      },
    });
  }, [navigate, email, phone, order, rowSelection]);

  const columns = React.useMemo<ColumnDef<Product>[]>(() => {
    const columnDefinitions: ColumnDef<Product>[] = [
      {
        header: t('OrderProductsTable.columns.name.title'),
        accessorKey: 'name',
        meta: { filterType: 'text' },
        cell: ({ row }) => <Typography variant="name">{row.original.name}</Typography>,
        enableColumnFilter: false,
      },
      {
        header: t('OrderProductsTable.columns.sku.title'),
        accessorKey: 'sku',
        meta: { filterType: 'text' },
        cell: ({ row }) => <Chip label={row.original.sku} />,
        enableColumnFilter: false,
      },
      {
        header: t('OrderProductsTable.columns.quantity.title'),
        accessorKey: 'qtyOrdered',
        meta: { filterType: 'number' },
        cell: ({ row }) => (
          <ProductQtyCard
            ordered={row.original.qtyOrdered}
            shipped={row.original.qtyShipped}
            returned={row.original.qtyReturned}
            refunded={row.original.qtyRefunded}
          />
        ),
        enableColumnFilter: false,
      },
    ];

    if (process.env.REACT_APP_FEATURE_RETURNS === 'true') {
      columnDefinitions.unshift({
        id: 'select',
        enableColumnFilter: false,
        header: ({ table }) => (
          <ReactTableCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <ReactTableCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      });
    }
    return columnDefinitions;
  }, [t]);

  const table = useReactTable({
    data: order?.items || inititalData,
    columns,
    state: {
      columnFilters,
      rowSelection,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (isNil(order)) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'background.paper',
          padding: 1.5,
          marginBottom: 2,
        }}>
        <Typography variant="h3" sx={{ lineHeight: '50px' }}>
          {t('UpdateOrderPage.products.title')}
        </Typography>
        <Flags authorizedFlags={['returns']}>
          {(order?.status === 'ready_for_store_pickup' || order?.status === 'picked_up_from_store') && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBackIcon />}
              disabled={isEmpty(rowSelection)}
              onClick={handleCreateReturnClick}>
              {t('OrderProductsTable.buttons.createReturn.title')}
            </Button>
          )}
        </Flags>
      </Box>
      <ReactTable table={table} title={t('OrderProductsTable.title')} />
    </>
  );
};

export default OrderProductsTable;
