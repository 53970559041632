import * as React from 'react';
import { Flags } from 'react-feature-flags';

import { useTranslation } from 'react-i18next';
import { Box, LinearProgress } from '@mui/material';

import useFetchOrder from '../hooks/useFetchOrder';
import useReturnStateParams from '../hooks/useReturnStateParams';

import HeadingWithIcon from '../shared/HeadingWithIcon';
import CreateReturnForm from '../shared/return/CreateReturnForm';

const CreateReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const { email, phone, orderNumber } = useReturnStateParams();

  const { isLoading } = useFetchOrder(orderNumber, email, phone);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Flags authorizedFlags={['returns']}>
      <Box>
        <Box sx={{ marginBottom: 6 }}>
          <HeadingWithIcon icon="arrowBack">{t('CreateReturnPage.title')}</HeadingWithIcon>
        </Box>
        <Box sx={{ maxWidth: theme => theme.breakpoints.values.lg }}>
          <CreateReturnForm />
        </Box>
      </Box>
    </Flags>
  );
};

export default CreateReturnPage;
