import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import HeadingWithIcon from '../shared/HeadingWithIcon';
import OrdersTable from '../shared/order/OrdersTable';

const OrdersPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 6 }}>
        <HeadingWithIcon icon="square">{t('OrdersPage.title')}</HeadingWithIcon>
        <Button variant="outlined" color="secondary" component={Link} to="/private/orders/search">
          {t('OrdersPage.buttons.search.title')}
        </Button>
      </Box>
      <OrdersTable />
    </Box>
  );
};

export default OrdersPage;
