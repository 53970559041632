import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import useSearchOrder from '../hooks/useSearchOrder';

import SearchOrderForm from '../shared/order/SearchOrderForm';
import HeadingWithIcon from '../shared/HeadingWithIcon';

const SearchOrderPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: searchOrder } = useSearchOrder();

  return (
    <Box>
      <Box sx={{ marginBottom: 6 }}>
        <HeadingWithIcon icon="search">{t('SearchOrderPage.title')}</HeadingWithIcon>
      </Box>
      <Box sx={{ maxWidth: theme => theme.breakpoints.values.sm }}>
        <SearchOrderForm
          onSubmit={async values => {
            try {
              const order = await searchOrder({ ...values });
              navigate(`/private/orders/${order.orderNumber}?email=${values.email}&phone=${values.phone}`);
            } catch (error) {
              console.error(error);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchOrderPage;
