import * as React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { getError } from '../../../utils/formikUtils';

import { CreateDigitalGiftCardFormValues } from '../../../types/dataTypes';

import useCreateDigitalGiftCard from '../../hooks/useCreateDigitalGiftCard';
import useFetchUserProfile from '../../hooks/useFetchUserProfile';

import PlusIcon from '../../icons/PlusIcon';
import EuroIcon from '../../icons/EuroIcon';
import FormErrorText from '../FormErrorText';
import { getGiftCardMinBalance } from '../../../utils/userUtils';

const CreateDigitalGiftCardForm: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { mutateAsync: createGiftCard } = useCreateDigitalGiftCard();
  const { data: userProfile, isLoading } = useFetchUserProfile();
  const giftCardMinBalance = getGiftCardMinBalance(userProfile);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        customerFirstName: Yup.string().required(t('Validation.errors.required')),
        customerLastName: Yup.string().required(t('Validation.errors.required')),
        customerEmail: Yup.string().email(t('Validation.errors.email')).required(t('Validation.errors.required')),
        recipientFirstName: Yup.string().required(t('Validation.errors.required')),
        recipientLastName: Yup.string().required(t('Validation.errors.required')),
        recipientPhone: Yup.string()
          .matches(/^\+[0-9]{5,15}$/, t('Validation.errors.phone'))
          .required(t('Validation.errors.required')),
        recipientEmail: Yup.string().email(t('Validation.errors.email')).required(t('Validation.errors.required')),
        messageBody: Yup.string().required(t('Validation.errors.required')),
        balance: Yup.number()
          .min(giftCardMinBalance, t('Validation.errors.min', { min: giftCardMinBalance }))
          .max(500, t('Validation.errors.max', { max: 500 }))
          .required(t('Validation.errors.required')),
      }),
    [t, giftCardMinBalance],
  );

  const formik = useFormik<CreateDigitalGiftCardFormValues>({
    initialValues: {
      customerFirstName: '',
      customerLastName: '',
      customerEmail: '',
      recipientFirstName: '',
      recipientLastName: '',
      recipientPhone: '',
      recipientEmail: '',
      messageBody: '',
      balance: 0,
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await createGiftCard(values);
        navigate('/private/gift-cards');
      } catch (error) {
        console.error(error);
      }
    },
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        {t('CreateDigitalGiftCardForm.texts.customer.title')}
      </Typography>
      <FormControl error={getError(formik, 'customerFirstName')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.customerFirstName.label')}</FormLabel>
        <OutlinedInput
          id="customerFirstName"
          name="customerFirstName"
          placeholder={t('CreateDigitalGiftCardForm.fields.customerFirstName.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.customerFirstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'customerFirstName')} />
      </FormControl>
      <FormControl error={getError(formik, 'customerLastName')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.customerLastName.label')}</FormLabel>
        <OutlinedInput
          id="customerLastName"
          name="customerLastName"
          placeholder={t('CreateDigitalGiftCardForm.fields.customerLastName.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.customerLastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'customerLastName')} />
      </FormControl>
      <FormControl error={getError(formik, 'customerEmail')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.customerEmail.label')}</FormLabel>
        <OutlinedInput
          id="customerEmail"
          name="customerEmail"
          type="email"
          placeholder={t('CreateDigitalGiftCardForm.fields.customerEmail.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.customerEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'customerEmail')} />
      </FormControl>
      <Divider />
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        {t('CreateDigitalGiftCardForm.texts.recipient.title')}
      </Typography>
      <FormControl error={getError(formik, 'recipientFirstName')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.recipientFirstName.label')}</FormLabel>
        <OutlinedInput
          id="recipientFirstName"
          name="recipientFirstName"
          placeholder={t('CreateDigitalGiftCardForm.fields.recipientFirstName.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.recipientFirstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'recipientFirstName')} />
      </FormControl>
      <FormControl error={getError(formik, 'recipientLastName')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.recipientLastName.label')}</FormLabel>
        <OutlinedInput
          id="recipientLastName"
          name="recipientLastName"
          placeholder={t('CreateDigitalGiftCardForm.fields.recipientLastName.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.recipientLastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'recipientLastName')} />
      </FormControl>
      <FormControl error={getError(formik, 'recipientPhone')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.recipientPhone.label')}</FormLabel>
        <OutlinedInput
          id="recipientPhone"
          name="recipientPhone"
          type="tel"
          placeholder={t('CreateDigitalGiftCardForm.fields.recipientPhone.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.recipientPhone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'recipientPhone')} />
      </FormControl>
      <FormControl error={getError(formik, 'recipientEmail')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.recipientEmail.label')}</FormLabel>
        <OutlinedInput
          id="recipientEmail"
          name="recipientEmail"
          type="email"
          placeholder={t('CreateDigitalGiftCardForm.fields.recipientEmail.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.recipientEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'recipientEmail')} />
      </FormControl>
      <Divider />
      <FormControl error={getError(formik, 'messageBody')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.messageBody.label')}</FormLabel>
        <OutlinedInput
          id="messageBody"
          name="messageBody"
          minRows={5}
          placeholder={t('CreateDigitalGiftCardForm.fields.messageBody.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.messageBody}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          multiline
        />
        <FormErrorText error={getError(formik, 'messageBody')} />
      </FormControl>
      <Divider />
      <Alert severity="info" variant="outlined" sx={{ marginBottom: 2 }}>
        {t('CreatePhysicalGiftCardForm.alerts.info.body', { min: giftCardMinBalance })}
      </Alert>
      <FormControl error={getError(formik, 'balance')} fullWidth>
        <FormLabel required>{t('CreateDigitalGiftCardForm.fields.balance.label')}</FormLabel>
        <OutlinedInput
          id="balance"
          name="balance"
          type="number"
          placeholder={t('CreateDigitalGiftCardForm.fields.balance.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.balance}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <EuroIcon />
            </InputAdornment>
          }
        />
        <FormErrorText error={getError(formik, 'balance')} />
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          disabled={formik.isSubmitting}
          component={Link}
          to="/private/gift-cards"
          sx={{ marginRight: 2 }}>
          {t('CreateDigitalGiftCardForm.buttons.cancel.title')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          startIcon={<PlusIcon />}>
          {t('CreateDigitalGiftCardForm.buttons.create.title')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateDigitalGiftCardForm;
