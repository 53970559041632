import * as React from 'react';
import * as Yup from 'yup';

import { isEmpty } from 'lodash';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, FormControl, FormControlLabel, FormLabel, OutlinedInput, Radio, RadioGroup } from '@mui/material';

import { getError } from '../../../utils/formikUtils';

import { SearchOrderFormValues } from '../../../types/dataTypes';

import FormErrorText from '../FormErrorText';

type Props = {
  onSubmit: (values: SearchOrderFormValues, helpers?: FormikHelpers<SearchOrderFormValues>) => void;
};

const SearchOrderForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      orderNumber: Yup.string()
        .matches(/^[0-9]{3,9}$/, t('Validation.errors.orderNumber'))
        .required(t('Validation.errors.required')),
      validateWith: Yup.string().required(t('Validation.errors.required')),
      email: Yup.string()
        .email(t('Validation.errors.email'))
        .when('validateWith', {
          is: 'email',
          then: Yup.string().required(t('Validation.errors.required')),
        }),
      phone: Yup.string()
        .matches(/^\+?[0-9]{5,15}$/, t('Validation.errors.phone'))
        .when('validateWith', {
          is: 'phone',
          then: Yup.string().required(t('Validation.errors.required')),
        }),
    });
  }, [t]);

  const formik = useFormik<SearchOrderFormValues>({
    initialValues: {
      orderNumber: '',
      validateWith: '',
      phone: '',
      email: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <FormControl error={!isEmpty(getError(formik, 'orderNumber'))} fullWidth>
        <FormLabel htmlFor="orderNumber" required>
          {t('SearchOrderForm.fields.orderNumber.label')}
        </FormLabel>
        <OutlinedInput
          id="orderNumber"
          name="orderNumber"
          placeholder={t('SearchOrderForm.fields.orderNumber.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.orderNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'orderNumber')} />
      </FormControl>
      <FormControl error={!isEmpty(getError(formik, 'validateWith'))} fullWidth>
        <FormLabel htmlFor="validateWith" required>
          {t('SearchOrderForm.fields.validateWith.label')}
        </FormLabel>
        <RadioGroup
          id="validateWith"
          name="validateWith"
          value={formik.values.validateWith}
          onChange={event => {
            formik.setFieldValue('phone', '');
            formik.setFieldValue('email', '');
            formik.handleChange(event);
          }}
          onBlur={formik.handleBlur}>
          <FormControlLabel value="email" control={<Radio />} label={t('ValidateWith.values.email.label')} />
          <FormControlLabel value="phone" control={<Radio />} label={t('ValidateWith.values.phone.label')} />
        </RadioGroup>
        <FormErrorText error={getError(formik, 'validateWith')} />
      </FormControl>
      {formik.values.validateWith === 'phone' ? (
        <FormControl error={!isEmpty(getError(formik, 'phone'))} fullWidth>
          <FormLabel htmlFor="phone" required>
            {t('SearchOrderForm.fields.phone.label')}
          </FormLabel>
          <OutlinedInput
            id="phone"
            name="phone"
            type="tel"
            placeholder={t('SearchOrderForm.fields.phone.placeholder')}
            disabled={formik.isSubmitting}
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormErrorText error={getError(formik, 'phone')} />
        </FormControl>
      ) : null}
      {formik.values.validateWith === 'email' ? (
        <FormControl error={!isEmpty(getError(formik, 'email'))} fullWidth>
          <FormLabel htmlFor="email" required>
            {t('SearchOrderForm.fields.email.label')}
          </FormLabel>
          <OutlinedInput
            id="email"
            name="email"
            type="email"
            placeholder={t('SearchOrderForm.fields.email.placeholder')}
            disabled={formik.isSubmitting}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormErrorText error={getError(formik, 'email')} />
        </FormControl>
      ) : null}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="secondary" component={Link} to="/private/orders" sx={{ marginRight: 2 }}>
          {t('SearchOrderForm.buttons.cancel.title')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
          {t('SearchOrderForm.buttons.search.title')}
        </Button>
      </Box>
    </Box>
  );
};

export default SearchOrderForm;
