import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';
import { useToast } from '../contexts/ToastContext';

import { DeliverOrderParams, DeliverOrderData } from '../../types/dataTypes';

const useDeliverOrder = () => {
  const { retailersDeskApi } = useRetailersDeskApi();
  const { createToast } = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  return useMutation(
    async ({ order, ...otherParams }: DeliverOrderParams) => {
      const customerName = `${order.customer.firstname || ''} ${order.customer.lastname || ''}`;

      const { data } = await retailersDeskApi.post<DeliverOrderData>(`/orders/${order.orderNumber}/deliver`, {
        pickedUpBy: otherParams.recipient === 'customer' ? customerName : otherParams.recipientName,
        identityVerified: otherParams.verificationMethod,
      });

      return data;
    },
    {
      onSuccess: () => {
        createToast({ type: 'success', text: t('useDeliverOrder.success') });
        queryClient.invalidateQueries(['orders']);
      },
      onError: () => {
        createToast({ type: 'error', text: t('useDeliverOrder.error') });
      },
    },
  );
};

export default useDeliverOrder;
