import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ChevronDownIcon = createSvgIcon(
  <path d="M6.99798 9.99799C6.99722 9.86638 7.02245 9.73592 7.07221 9.61408C7.12198 9.49224 7.1953 9.38143 7.28798 9.28799C7.38095 9.19426 7.49155 9.11986 7.61341 9.06909C7.73527 9.01833 7.86597 8.99219 7.99798 8.99219C8.13 8.99219 8.2607 9.01833 8.38256 9.06909C8.50442 9.11986 8.61502 9.19426 8.70798 9.28799L11.998 12.588L15.288 9.28799C15.4763 9.09968 15.7317 8.99389 15.998 8.99389C16.1298 8.99389 16.2604 9.01987 16.3822 9.07033C16.5041 9.12079 16.6147 9.19475 16.708 9.28799C16.8012 9.38122 16.8752 9.49191 16.9256 9.61374C16.9761 9.73556 17.0021 9.86613 17.0021 9.99799C17.0021 10.2643 16.8963 10.5197 16.708 10.708L12.708 14.708C12.615 14.8017 12.5044 14.8761 12.3826 14.9269C12.2607 14.9776 12.13 15.0038 11.998 15.0038C11.866 15.0038 11.7353 14.9776 11.6134 14.9269C11.4915 14.8761 11.3809 14.8017 11.288 14.708L7.28798 10.708C7.1953 10.6145 7.12198 10.5037 7.07221 10.3819C7.02245 10.2601 6.99722 10.1296 6.99798 9.99799Z" />,
  'ChevronDown',
);

export default ChevronDownIcon;
