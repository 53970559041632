import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const ChevronVerticalIcon = createSvgIcon(
  <path d="M14.6163 10.9258C14.7381 10.9755 14.8686 11.0008 15.0002 11C15.1318 11.0008 15.2623 10.9755 15.3841 10.9258C15.5059 10.876 15.6167 10.8027 15.7102 10.71C15.8039 10.617 15.8783 10.5064 15.9291 10.3846C15.9798 10.2627 16.006 10.132 16.006 10C16.006 9.86799 15.9798 9.73728 15.9291 9.61542C15.8783 9.49356 15.8039 9.38296 15.7102 9.29L12.7102 6.29C12.6172 6.19627 12.5066 6.12188 12.3848 6.07111C12.2629 6.02034 12.1322 5.9942 12.0002 5.9942C11.8682 5.9942 11.7375 6.02034 11.6156 6.07111C11.4937 6.12188 11.3831 6.19627 11.2902 6.29L8.29019 9.29C8.10188 9.4783 7.99609 9.7337 7.99609 10C7.99609 10.2663 8.10188 10.5217 8.29019 10.71C8.47849 10.8983 8.73388 11.0041 9.00019 11.0041C9.26649 11.0041 9.52188 10.8983 9.71019 10.71L12.0002 8.41L14.2902 10.71C14.3836 10.8027 14.4944 10.876 14.6163 10.9258ZM11.6163 17.9258C11.7381 17.9755 11.8686 18.0008 12.0002 18C12.1318 18.0008 12.2623 17.9755 12.3841 17.9258C12.5059 17.876 12.6167 17.8027 12.7102 17.71L15.7102 14.71C15.8985 14.5217 16.0043 14.2663 16.0043 14C16.0043 13.7337 15.8985 13.4783 15.7102 13.29C15.5219 13.1017 15.2665 12.9959 15.0002 12.9959C14.7339 12.9959 14.4785 13.1017 14.2902 13.29L12.0002 15.59L9.71019 13.29C9.52188 13.1017 9.26649 12.9959 9.00019 12.9959C8.73388 12.9959 8.47849 13.1017 8.29019 13.29C8.10188 13.4783 7.99609 13.7337 7.99609 14C7.99609 14.2663 8.10188 14.5217 8.29019 14.71L11.2902 17.71C11.3836 17.8027 11.4944 17.876 11.6163 17.9258Z" />,
  'ChevronVertical',
);

export default ChevronVerticalIcon;
