import * as React from 'react';

import { createSvgIcon } from '@mui/material';

const RadioIcon = createSvgIcon(
  <>
    <circle cx="12" cy="12" r="11.5" />
    <circle cx="12" cy="12" r="4.5" fill="white" />
  </>,
  'Radio',
);

export default RadioIcon;
