import * as React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  url: string;
  title: string;
};

const PdfButton: React.FC<Props> = ({ url, title }) => {
  const { t } = useTranslation();
  return (
    <Button type="button" variant="outlined" color="secondary" href={url} target="_blank">
      {t(title)}
    </Button>
  );
};

export default PdfButton;
