import * as React from 'react';

import { isNil } from 'lodash';

import { Store } from '../../types/dataTypes';

type ContextProps = {
  store?: Store;
  updateStore: (store: Store) => void;
};

type ProviderProps = {
  children: React.ReactNode;
};

const StoreContext = React.createContext<ContextProps>({
  store: undefined,
  updateStore: () => undefined,
});

const getPersistedStore = () => {
  const store = localStorage.getItem('StoreContext.store');

  return isNil(store) ? undefined : JSON.parse(store);
};

const setPersistedStore = (store: Store) => {
  localStorage.setItem('StoreContext.store', JSON.stringify(store));
};

export const StoreProvider: React.FC<ProviderProps> = ({ children }) => {
  const [store, setStore] = React.useState<Store | undefined>(getPersistedStore);

  const contextValue = React.useMemo(() => {
    const updateStore = (nextStore: Store) => {
      setPersistedStore(nextStore);
      setStore(nextStore);
    };

    return {
      store,
      updateStore,
    };
  }, [store]);

  return <StoreContext.Provider value={contextValue}>{children}</StoreContext.Provider>;
};

export const useStore = () => {
  const context = React.useContext(StoreContext);
  if (isNil(context)) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};
