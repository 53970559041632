import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LogoutPage = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h2" sx={{ marginBottom: 6 }}>
        {t('LogoutPage.title')}
      </Typography>
      <Button variant="contained" color="primary" href="/.auth/login/aad?post_login_redirect_uri=/private/">
        {t('LogoutPage.buttons.login.title')}
      </Button>
    </Box>
  );
};

export default LogoutPage;
