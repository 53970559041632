import * as React from 'react';

import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import { getUserProfileName } from '../../utils/userUtils';

import { useDialog } from '../contexts/DialogContext';
import { usePopover } from '../contexts/PopoverContext';
import { useStore } from '../contexts/StoreContext';

import useFetchUserProfile from '../hooks/useFetchUserProfile';

import MoreIcon from '../icons/MoreIcon';
import UserPopover from './UserPopover';

const UserAvatar: React.FC = () => {
  const { t } = useTranslation();
  const { openPopover } = usePopover();
  const { openDialog } = useDialog();
  const { store } = useStore();

  const { data: userProfile, isLoading } = useFetchUserProfile();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isNil(userProfile)) {
    return null;
  }

  return (
    <>
      <UserPopover />
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="name" sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '24px' }}>
              {getUserProfileName(userProfile)}
            </Typography>
            <IconButton
              color="secondary"
              size="small"
              aria-label={t('UserAvatar.buttons.openUserMenu.title')}
              onClick={event => openPopover('UserPopover', event.currentTarget)}>
              <MoreIcon />
            </IconButton>
          </Box>
          <Button variant="link" color="secondary" sx={{ padding: 0 }} onClick={() => openDialog('SelectStoreDialog')}>
            {isNil(store) ? t('UserAvatar.buttons.selectStore.title') : store.name}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserAvatar;
