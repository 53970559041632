import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flags } from 'react-feature-flags';

import SearchGiftCardForm from '../shared/giftCard/SearchGiftCardForm';
import HeadingWithIcon from '../shared/HeadingWithIcon';

const GiftCardsPage = () => {
  const { t } = useTranslation();

  return (
    <Flags authorizedFlags={['giftcard']}>
      <Box>
        <Box sx={{ marginBottom: 6 }}>
          <HeadingWithIcon icon="gift">{t('GiftCardsPage.title')}</HeadingWithIcon>
        </Box>
        <Box>
          <SearchGiftCardForm />
        </Box>
      </Box>
    </Flags>
  );
};

export default GiftCardsPage;
