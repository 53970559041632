import * as React from 'react';

import { get, isNil } from 'lodash';
import Axios, { AxiosInstance } from 'axios';

type ContextProps = {
  retailersDeskApi: AxiosInstance;
};

type ProviderProps = {
  children: React.ReactNode;
};

const retailersDeskApi = Axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const RetailersDeskApiContext = React.createContext<ContextProps | undefined>(undefined);

export const RetailersDeskApiProvider: React.FC<ProviderProps> = ({ children }) => {
  const value = React.useMemo(() => {
    return { retailersDeskApi };
  }, []);

  React.useEffect(() => {
    const requestInterceptor = retailersDeskApi.interceptors.request.use(async request => {
      return request;
    });

    const responseInterceptor = retailersDeskApi.interceptors.response.use(
      response => response,
      async error => {
        const status = get(error, 'response.status');

        if (status === 401) {
          console.log('401');
        }

        return Promise.reject(error);
      },
    );

    return () => {
      retailersDeskApi.interceptors.request.eject(requestInterceptor);
      retailersDeskApi.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return <RetailersDeskApiContext.Provider value={value}>{children}</RetailersDeskApiContext.Provider>;
};

export const useRetailersDeskApi = () => {
  const context = React.useContext(RetailersDeskApiContext);

  if (isNil(context)) {
    throw new Error('useRetailersDeskApi must be used within a RetailersDeskApiProvider');
  }

  return context;
};
