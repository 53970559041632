import * as React from 'react';

import { get } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Column, Table } from '@tanstack/react-table';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = { column: Column<any, any>; table: Table<any> };

const ReactTableSqlDateFilter: React.FC<Props> = ({ column }) => {
  const { t } = useTranslation();

  const filterValue = column.getFilterValue() as [string, string];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <DatePicker
        inputFormat="dd.MM.yyyy"
        value={get(filterValue, '0') || null}
        onChange={(nextFilterValue: DateTime | null) => {
          column.setFilterValue((prevFilterValue: [string, string]) => [
            nextFilterValue?.toSQLDate(),
            get(prevFilterValue, '1'),
          ]);
        }}
        renderInput={textFieldProps => (
          <TextField
            {...textFieldProps}
            id={`${column.id}after`}
            variant="outlined"
            sx={{ marginBottom: '2px' }}
            inputProps={{
              ...textFieldProps.inputProps,
              placeholder: t('ReactTableSqlDateFilter.fields.after.placeholder'),
            }}
          />
        )}
      />
      <DatePicker
        inputFormat="dd.MM.yyyy"
        value={get(filterValue, '1') || null}
        onChange={(nextFilterValue: DateTime | null) => {
          column.setFilterValue((prevFilterValue: [string, string]) => [
            get(prevFilterValue, '0'),
            nextFilterValue?.toSQLDate(),
          ]);
        }}
        renderInput={textFieldProps => (
          <TextField
            {...textFieldProps}
            id={`${column.id}before`}
            variant="outlined"
            sx={{ marginBottom: 0 }}
            inputProps={{
              ...textFieldProps.inputProps,
              placeholder: t('ReactTableSqlDateFilter.fields.before.placeholder'),
            }}
          />
        )}
      />
    </Box>
  );
};

export default ReactTableSqlDateFilter;
