import * as React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import RootLayout from '../shared/RootLayout';

import HomePage from '../pages/HomePage';
import OrdersPage from '../pages/OrdersPage';
import UpdateOrderPage from '../pages/UpdateOrderPage';
import GiftCardsPage from '../pages/GiftCardsPage';
import UpdateGiftCardPage from '../pages/UpdateGiftCardPage';
import CreateGiftCardPage from '../pages/CreateGiftCardPage';
import CreateReturnPage from '../pages/CreateReturnPage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import DeveloperPage from '../pages/DeveloperPage';
import SearchOrderPage from '../pages/SearchOrderPage';

const RootRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="public">
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route index element={<Navigate to="login" />} />
        </Route>
        <Route path="private" element={<RootLayout />}>
          <Route index element={<HomePage />} />
          <Route path="developer" element={<DeveloperPage />} />
          <Route path="orders">
            <Route index element={<OrdersPage />} />
            <Route path="search" element={<SearchOrderPage />} />
            <Route path=":orderNumber" element={<UpdateOrderPage />} />
          </Route>
          <Route path="gift-cards">
            <Route index element={<GiftCardsPage />} />
            <Route path="create" element={<CreateGiftCardPage />} />
            <Route path=":cardNumber" element={<UpdateGiftCardPage />} />
          </Route>
          <Route path="returns">
            <Route path="create" element={<CreateReturnPage />} />
          </Route>
        </Route>
        <Route index element={<Navigate to="private" />} />
      </Route>
    </Routes>
  );
};

export default RootRouter;
