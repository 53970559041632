import * as React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { getError } from '../../../utils/formikUtils';

import { CreatePhysicalGiftCardFormValues } from '../../../types/dataTypes';

import useCreatePhysicalGiftCard from '../../hooks/useCreatePhysicalGiftCard';

import PlusIcon from '../../icons/PlusIcon';
import EuroIcon from '../../icons/EuroIcon';
import FormErrorText from '../FormErrorText';
import { getGiftCardMinBalance } from '../../../utils/userUtils';
import useFetchUserProfile from '../../hooks/useFetchUserProfile';

const CreatePhysicalGiftCardForm: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { mutateAsync: createGiftCard } = useCreatePhysicalGiftCard();
  const { data: userProfile, isLoading } = useFetchUserProfile();
  const giftCardMinBalance = getGiftCardMinBalance(userProfile);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        cardNumber: Yup.string().required(t('Validation.errors.required')),
        balance: Yup.number()
          .min(giftCardMinBalance, t('Validation.errors.min', { min: giftCardMinBalance }))
          .max(500, t('Validation.errors.max', { max: 500 }))
          .required(t('Validation.errors.required')),
      }),
    [t, giftCardMinBalance],
  );

  const formik = useFormik<CreatePhysicalGiftCardFormValues>({
    initialValues: {
      cardNumber: '',
      balance: 0,
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await createGiftCard(values);
        navigate('/private/gift-cards');
      } catch (error) {
        console.error(error);
      }
    },
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <FormControl error={getError(formik, 'cardNumber')} fullWidth>
        <FormLabel required>{t('CreatePhysicalGiftCardForm.fields.cardNumber.label')}</FormLabel>
        <OutlinedInput
          id="cardNumber"
          name="cardNumber"
          placeholder={t('CreatePhysicalGiftCardForm.fields.cardNumber.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.cardNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FormErrorText error={getError(formik, 'cardNumber')} />
      </FormControl>
      <Divider />
      <Alert severity="info" variant="outlined" sx={{ marginBottom: 2 }}>
        {t('CreatePhysicalGiftCardForm.alerts.info.body', { min: giftCardMinBalance })}
      </Alert>
      <FormControl error={getError(formik, 'balance')} fullWidth>
        <FormLabel required>{t('CreatePhysicalGiftCardForm.fields.balance.label')}</FormLabel>
        <OutlinedInput
          id="balance"
          name="balance"
          type="number"
          placeholder={t('CreatePhysicalGiftCardForm.fields.balance.placeholder')}
          disabled={formik.isSubmitting}
          value={formik.values.balance}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <EuroIcon />
            </InputAdornment>
          }
        />
        <FormErrorText error={getError(formik, 'balance')} />
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          disabled={formik.isSubmitting}
          component={Link}
          to="/private/gift-cards"
          sx={{ marginRight: 2 }}>
          {t('CreatePhysicalGiftCardForm.buttons.cancel.title')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          startIcon={<PlusIcon />}>
          {t('CreatePhysicalGiftCardForm.buttons.create.title')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreatePhysicalGiftCardForm;
