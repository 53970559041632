import { get } from 'lodash';
import { useQuery } from 'react-query';

import { useRetailersDeskApi } from '../contexts/RetailersDeskApiContext';

import { FetchReturnConditionsData } from '../../types/dataTypes';

const useFetchReturnConditions = () => {
  const { retailersDeskApi } = useRetailersDeskApi();

  return useQuery(['returns', 'conditions', 'fi'], async () => {
    const { data } = await retailersDeskApi.get<FetchReturnConditionsData>(`/returns/options`, {
      params: {
        lang: 'fi',
        attribute: 'condition',
      },
    });

    return get(data, 'options');
  });
};

export default useFetchReturnConditions;
